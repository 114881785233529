import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
// import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-payments-modal',
  templateUrl: './payments-modal.component.html',
  styleUrls: ['./payments-modal.component.scss'],
})
export class PaymentsModalComponent implements OnInit {
  paymentTypes = [
    {
      value: '040201',
      description:
        '040201 - COTE DEFALCATE DIN IMPOZITUL PE VENIT[-FD-](Persoana Fizica)',
    },
    {
      value: '040201',
      description:
        '040201 - COTE DEFALCATE DIN IMPOZITUL PE VENIT[-FD-](Persoana Juridica)',
    },
    {
      value: '040204',
      description:
        '040204 - SUME ALOCATE DE CJ PT. ECHILIBRAREA BUG.LOCALE[-FD-](Persoana Fizica)',
    },
    {
      value: '040204',
      description:
        '040204 - SUME ALOCATE DE CJ PT. ECHILIBRAREA BUG.LOCALE[-FD-](Persoana Juridica)',
    },
  ];

  formPayment: FormGroup = new FormGroup({
    paymentType: new FormControl(''),
    paymentSum: new FormControl(''),
    lastName: new FormControl(''),
    firstName: new FormControl(''),
    cnp_cui: new FormControl(''),
  });

  constructor(private formsBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initPaymentForm();
  }

  initPaymentForm() {
    this.formPayment = this.formsBuilder.group({
      paymentType: ['', [Validators.required]],
      paymentSum: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      cnp_cui: ['', [Validators.required]],
    });
  }

  submitForm() {
    const value = this.formPayment.value;
    console.log(value);
    this.formPayment.reset();
  }

  // suportDialogRef: MatDialogRef<any> | undefined;
  // closeDialog() {
  //   this.suportDialogRef?.close();
  // }
}
