import { Component, Input } from '@angular/core';

@Component({
  selector: 'settings-card',
  templateUrl: './settings-card.component.html',
  styleUrls: ['./settings-card.component.scss']
})
export class SettingsCardComponent {
  @Input() isGeneral: boolean = false;
  @Input() isPerson: boolean = false;
  @Input() isPasswordSetting: boolean = false;
  @Input() isAddress: boolean = false;
  
  @Input() hasButton: boolean = false;
  @Input() cardTitle!: string;
  @Input() buttonName!: string;
  @Input() iconName!: string;

  @Input() personRows: string[] = [];
  @Input() personInfo: string[] = [];
  @Input() dataPerson: {field: string, value: string}[] = [];
}
