import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DocumenteleMeleService {
    private basePath = environment.interop.authCore.basePath;
    public fileDownloadUrl = this.basePath + environment.interop.authCore.documenteleMele.fileDownload;

    constructor() { }


}
