import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumenteleMeleComponent } from './documentele-mele.component';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
    declarations: [DocumenteleMeleComponent],
    imports: [
        CommonModule,
        MaterialModule
    ]
})
export class DocumenteleMeleModule { }
