import { Component, Input, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { AuthService } from '../../auth-module/services/auth.service'
import { finalize } from 'rxjs';

import { AlertDialogComponent } from 'src/app/utils/alert-dialog/alert-dialog.component';
import { DialogService } from 'src/app/utils/services/dialog.service';

@Component({
	selector: 'app-frequent-questions',
	templateUrl: './frequent-questions.component.html',
	styleUrls: ['./frequent-questions.component.scss']
})
export class FrequentQuestionsComponent implements OnInit {
	PageSection: any = null
	FAQList: any = []

	public isCollapsed = false;

	// dialogRef: any
	isLoading = false;

	constructor(
		private authService: AuthService,
		private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<FrequentQuestionsComponent>,
		private dialogService: DialogService,
	) {

		console.log('here we are?')
		this.PageSection = data.PageSection

	}

	closeDialog(data: any = null) {
		this.dialogRef.close({
			data: data
		});
	}

	cancel() {
		this.dialogRef.close({
			data: 'cancel'
		});
	}

	ngOnInit() : void {
		this.getFAQS()
	}

	getFAQS() {
		var self = this
		this.isLoading = true
		this.authService.getFAQ(this.PageSection)
			.pipe(
				finalize(() => this.isLoading = false)
			)
			.subscribe({
				next: (res: any) => {
					console.log(res, 'faq component')
					let response = (typeof res.status_code !== 'undefined' ? res : res.error)
					if (typeof response.status_code !== 'undefined') {
						if (response.status_code == 200 && typeof response.data !== 'undefined') {

							self.FAQList = response.data
							self.isLoading = false
							return false;

						} else {
							// let errorMessage = environment.config.customNotifications.generalMessages.error;
							// response.errors.message.forEach(function (msg) {
							// 	errorMessage = msg;
							// })
							// await self.notificationService.warningSwal(
							// 	self.errorTitle, errorMessage, self.errorIcon
							// );
							// self.loaded = 1
							return false;
						}

					} else {
						// add sentry
						// let errorMessage = environment.config.customNotifications.generalMessages.error;
						// await self.notificationService.warningSwal(
						// 	self.errorTitle, errorMessage, self.errorIcon
						// );

						// self.loaded = 1
						return false
					}
				},
				error: () => {
					const dialogRef = this.dialog.open(
						AlertDialogComponent,
						this.dialogService.getDialogConfig()
					);
					Object.assign(dialogRef.componentInstance, { dialogRef });
				}
			})
	}
}
