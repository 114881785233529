import { Component, Input, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { EventsService } from '../../../services/events.service';
import { USER_UPDATED } from '../../../shared/events';
import { map, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../../../services/loading.service';
import { MatDrawerMode } from '@angular/material/sidenav';
import { AuthService } from 'src/app/auth-module/services/auth.service';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { ConfigExtensions } from 'src/app/utils/config-extensions';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  username: string = '';
  usernameLetters: string = '';
  userNotifications = 0;
  isLoading: any;
  isCollapsed = false;
  sidenavMode: MatDrawerMode = 'side';
  sidenavMargin: number = 280;
  public isMenuOpen: boolean = false;
  constructor(
    private store: StoreService,
    private eventService: EventsService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private router: Router
  ) {}

  get showBell() {
      return !ConfigExtensions.isProductionWithLimitedFeatures();
  }

  ngOnInit(): void {
    this.getUserDetails();

    // EVENT COMMUNICATION : subscribe to event in order to do actions
    this.eventService.getEvent(USER_UPDATED).subscribe(() => {
      this.getUserDetails();
    });

    // LOADING SERVICE SUBSCRIPTION
    this.loadingService.loading$
      .pipe(takeUntil(this._destroying$))
      .subscribe((isLoading: boolean) => {
        setTimeout(() => (this.isLoading = isLoading), 0);
      });
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  getUserDetails() {
    const user = localStorage.getItem(environment.config.userKey);
    if (!user) return;

    let jsonUser = JSON.parse(user);
    if (jsonUser && jsonUser.prenume && jsonUser.nume) {
      this.username = jsonUser.prenume + ' ' + jsonUser.nume;
    } else {
      this.username = 'Not logged in';
    }

    if (jsonUser && jsonUser.notificari) {
      this.userNotifications = jsonUser.notificari;
    }

    this.extractLetters(this.username);
  }

  extractLetters(username: string) {
    const firstLetters = username.split(' ').map((word) => word.charAt(0));
    this.usernameLetters = firstLetters.slice(0, 2).join('');
  }

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
    this.sidenavMargin = this.isCollapsed ? 85 : 280;
  }
  isProduction() {
      return ConfigExtensions.isProductionWithLimitedFeatures();
  }

  logout() {
    this.authService.logout();
    this.authService.clearStorage();
    // this.permissionsService.resetUserPermissions();
    this.router.navigate(['/pages/login']);
  }
  public onSidenavClick(): void {
    this.isMenuOpen = false;
  }
}
