import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { UserService } from '../services/user.service';

@Injectable({
	providedIn: 'root'
})
export class UserDataGuard implements CanActivate {

	constructor(private userService: UserService) { }

	canActivate(): Observable<boolean> {
		return this.userService.isDataLoadedObservable();
	}
}
