import { Component } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
  message = `Începând cu 3 ian. 2022, interacțiunea cu cetățenii se desfășoară la
  Biroul de Asistență (Grand Arena Mall, Etajul 1, Bd. Metalurgiei Nr.
  12-18), pe baza unei programări confirmate -`;
  link = `Programare pentru Asistență`;

  infos = [
    {
      message: this.message,
      link: this.link,
    },
    {
      message: this.message,
      link: this.link,
    },
    {
      message: this.message,
      link: this.link,
    },
  ];
}
