<div class="container">
    <mat-card-header class="solicitare-card mb-5">
        <span (click)="toggleShowSolicitare()" class="solicitare-card__back">
            <mat-icon class="back-icon">keyboard_arrow_left</mat-icon>
            <p>Întrebări Frecvente </p>
        </span>
        <p class="solicitare-card__title">Suport Tehnic</p>
        <button type="button" class="close" aria-label="Close" (click)="closeDialog()" [disabled]="loading">
            <span aria-hidden="true">&times;</span>
        </button>
    </mat-card-header>

    <mat-card-content class="solicitare-card__content">
        <form class="solicitare-card__form" [formGroup]="form" (ngSubmit)="submitForm()">
            <ng-container *ngIf="!isDashboardRoute()">
                <mat-form-field appearance="fill">
                    <mat-label>Nume</mat-label>
                    <input type="text" matInput formControlName="nume">
                    <mat-error *ngIf="!form.controls['nume'].valid && form.controls['nume'].touched">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Prenume</mat-label>
                    <input type="text" matInput formControlName="prenume">
                    <mat-error *ngIf="!form.controls['prenume'].valid && form.controls['prenume'].touched">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Număr de telefon</mat-label>
                    <button type="button" class="country-selector" mat-button matPrefix [matMenuTriggerFor]="menu">
                        <mat-icon class="expand-icon">expand_more</mat-icon>
                        {{ currentOption.flag }} {{ currentOption.dial_code }}
                    </button>
                    <mat-menu #menu="matMenu">
                        <button type="button" mat-menu-item (click)="changeCountry(optionRom)">
                            <span class="flag-wrapper">🇷🇴</span>
                            <span class="label-wrapper">
                                {{ optionRom.name }} {{ optionRom.dial_code }}
                            </span>
                        </button>
                        <mat-divider class="divider"></mat-divider>
                        <button type="button" mat-menu-item *ngFor="let option of options"
                            (click)="changeCountry(option)">
                            <span class="flag-wrapper">{{ option.flag }}</span>
                            <span class="label-wrapper">
                                {{ option.name }} {{ option.dial_code }}
                            </span>
                        </button>
                    </mat-menu>
                    <input type="text" matInput formControlName="telefon" />
                    <mat-error *ngIf="!form.controls['telefon'].valid && form.controls['telefon'].touched">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input type="text" matInput formControlName="email">
                    <mat-error *ngIf="!form.controls['email'].valid && form.controls['email'].touched">
                        Câmp obligatoriu
                    </mat-error>
                </mat-form-field>
            </ng-container>
            <mat-form-field appearance="fill">
                <mat-label>Adresa</mat-label>
                <input matInput type="text" formControlName="adresa">
                <mat-error *ngIf="!form.controls['adresa'].valid && form.controls['adresa'].touched">
                    Câmp obligatoriu
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Subiect</mat-label>
                <input matInput type="text" formControlName="subiect">
                <mat-error *ngIf="!form.controls['subiect'].valid && form.controls['subiect'].touched">
                    Câmp obligatoriu
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Mențiuni</mat-label>
                <textarea type="text" matInput formControlName="observatii"></textarea>
                <mat-error *ngIf="!form.controls['observatii'].valid && form.controls['observatii'].touched">
                    Câmp obligatoriu
                </mat-error>
            </mat-form-field>

            <div class="solicitare-card__upload">
                <div class="solicitare-card__upload-header">
                    <p class="upload-text">Fotografii/Documente</p>
                    <button type="button" class="upload-btn" (click)="fileInput.click()">
                        <mat-icon class="upload-icon">cloud_upload</mat-icon>
                        ÎNCARCĂ
                    </button>
                </div>
                <div class="solicitare-card__upload-files"
                    *ngIf="form.value.files.length > 0 && !uploading.documente_fotografii">
                    <div class="solicitare-card__upload-file"
                        *ngFor="let uploadedFile of form.value.files; let i = index">
                        <span class="solicitare-card__upload-file-name">
                            {{ (uploadedFile.original_name.length > 30) ? (uploadedFile.original_name | slice: 0:30) +
                            '..' : (uploadedFile.original_name)
                            }}
                        </span>
                        <div>
                            <button class="solicitare-card__upload-file-delete" type="button"
                                matTooltip="Șterge fișierul încărcat" (click)="removeFile(i)">
                                <img class="btn-img" src="assets/icons/delete.svg" alt="" />
                            </button>
                        </div>
                    </div>
                </div>
                <mat-spinner diameter="32" style="margin-right: 8px; margin-top: 4px"
                    *ngIf="uploading.documente_fotografii"></mat-spinner>

                <input type="file" accept="image/png, image/jpeg, image/gif, image/jpg, application/pdf"
                    id="upload-file-input-0" #fileInput name="fileInput"
                    (change)="addFile($event, 'documente_fotografii')" multiple style="display: none">
            </div>

            <div class="solicitare-card__info">
                <p class="info">
                    <mat-icon class="info-icon red-text">info</mat-icon>Câmpurile marcate cu
                    <span class="red-text">&nbsp;*&nbsp;</span>sunt obligatorii
                </p>
                <p class="info">
                    <mat-icon class="info-icon red-text">info</mat-icon>Fișierele atașate trebuie să fie de tip imagine
                    sau PDF, de maxim 10MB fișierul
                </p>
                <div class="d-flex align-items-center">
                    <mat-checkbox formControlName="prelucrareDate" color="primary"></mat-checkbox>
                    <strong class="m-0">Sunt de acord cu prelucrarea datelor în conformitate cu politica de
                        confidențialitate și securitate a datelor cu caracter personal.</strong>
                </div>
            </div>
            <div class="solicitare-card__actions">
                <button type="button" class="cancel-btn" (click)="closeDialog()" [disabled]="loading">Anulați</button>
                <button type="submit" class="send-btn" [disabled]="!form.valid || loading">Trimite Solicitare</button>
            </div>
        </form>
    </mat-card-content>
</div>