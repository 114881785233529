import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { AnnouncementsComponent } from './announcements.component';

@NgModule({
  declarations: [
    AnnouncementsComponent
  ],
  imports: [
    CommonModule, MaterialModule
  ],
  exports: [AnnouncementsComponent]
})
export class AnnouncementsModule { }
