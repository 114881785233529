import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-open-video',
	templateUrl: './open-video.component.html',
	styleUrls: ['./open-video.component.scss'],
})
export class OpenVideoComponent {
	public safeUrl: SafeResourceUrl;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { link: SafeResourceUrl },
        private dialogRef: MatDialogRef<OpenVideoComponent>,
        private sanitizer: DomSanitizer
    ) {
        this.safeUrl = data.link;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
