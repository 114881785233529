import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RegistreService {

    private basePath = environment.interop.urbanismCore.basePath;
    private getRegistreListURL = this.basePath + environment.interop.urbanismCore.registre.getRegistre;

    constructor(private http: HttpClient) { }

    getRegistre() {
        return this.http
            .get<{ errors: boolean, data: any[], status_code: number }>(
                this.getRegistreListURL
            )
            .pipe(
                map((res) =>
                    res.data.map((item) => ({
                        id: item.id,
                        name: item.name,
                        slug: item.slug
                    }))
                ),
                catchError((err) => {
                    console.log('Error in get registre types: ', err);
                    return throwError(err);
                })
            )
    }
}
