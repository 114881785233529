import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    errorTitle: string = environment.config.customNotifications.headers.error;
    errorIcon: string = environment.config.customNotifications.icons.error;

    constructor() { }

    async warningSwal(title: any, text: any, icon: any, okText = 'Închide', cancelText = 'Nu', showCancel = false): Promise<any> {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (
                    mutation.type === 'attributes' &&
                    document.body.classList.contains('swal2-height-auto')
                ) {
                    document.body.classList.remove('swal2-height-auto');
                    document.body.style.height = '100%';
                }
                if (document.body.style.paddingRight === '15px') {
                    document.body.style.paddingRight = '';
                }
            });
        });

        observer.observe(document.body, { attributes: true });
        return await Swal.fire({
            icon: icon,
            title: title,
            html: text,
            // footer: '<a href>Why do I have this issue?</a>'
            showCancelButton: showCancel,
            showConfirmButton: true,
            confirmButtonText: okText,
            confirmButtonColor: '#0044cc',
            cancelButtonText: cancelText,
            reverseButtons: true
        }).finally(() => {
            observer.disconnect(); // Stop observing when the Swal is closed
        });
    }

    warningSwalWithSupport() { }

    handleHttpError(res: any) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            var errMessages = '';
            res.errors.message.forEach((msg: string) => {
                errMessages += msg + '<br>';
            });
            this.warningSwal(this.errorTitle, errMessages, this.errorIcon);
        } else {
            let errorMessage =
                environment.config.customNotifications.generalMessages.error;
            this.warningSwal(this.errorTitle, errorMessage, this.errorIcon);
        }
    }
}
