import { Component, HostListener, Inject, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PDFDocumentProxy } from "ng2-pdf-viewer";
import { finalize } from 'rxjs';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { PreviewDocumentModel } from '../../models';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'app-preview-document-modal',
    templateUrl: './preview-document-modal.component.html',
    styleUrls: ['./preview-document-modal.component.scss']
})
export class PreviewDocumentModalComponent {
    public pdfSource: Uint8Array = new Uint8Array();
    imageSource: SafeUrl | null = null;
    unsupportedImage: boolean = false;
    rotateDegrees: number = 0;

    public documentRead: boolean = false;
    public isLoading: boolean;
    public docLoadedWithSuccess: boolean = false;

    // general error
    errorTitle: string = environment.config.customNotifications.headers.error;
    errorIcon: string = environment.config.customNotifications.icons.error;
    errorType: string = environment.config.customNotifications.icons.error;

    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        private dialogRef: MatDialogRef<PreviewDocumentModalComponent>,
        private notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) public data: PreviewDocumentModel
    ) {
        const currentDocumentId = data.documentId;
        const fullPath = data.apiPath;

        this.isLoading = true;
        this.docLoadedWithSuccess = false;

        this.http.get(fullPath, { responseType: 'blob' as 'json' })
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(
                {
                    next: (response: any) => {
                        // all ok
                        const blob = new Blob([response], { type: response.type });
                        const fileReader = new FileReader();
                        fileReader.onload = () => {
                            switch (data.extension) {
                                case "pdf":
                                    this.pdfSource = new Uint8Array(fileReader.result as ArrayBuffer);
                                    break;
                                case "png":
                                case "jpg":
                                case "jpeg":
                                    const imageUrl = URL.createObjectURL(blob);
                                    this.imageSource = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
                                    break;
                                default:
                                    this.imageSource = null;
                                    break;
                            }
                            this.pdfSource = new Uint8Array(fileReader.result as ArrayBuffer);
                        };
                        fileReader.readAsArrayBuffer(blob);
                        this.docLoadedWithSuccess = true;
                    },
                    error: (res: any) => {
                        this.notificationService.handleHttpError(res.error);
                    }
                });
    }

    onLoad(pdf: PDFDocumentProxy) {
        this.isLoading = false;
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    get isPdf(): boolean {
        return this.data.extension == 'pdf'
    }

    get isImage(): boolean {
        return this.data.extension == 'png' || this.data.extension == 'jpg' || this.data.extension == 'jpeg'
    }

    get rotateClass(): string {
        return `rotate(${this.rotateDegrees}deg)`;
    }

    rotateImage(): void {
        this.rotateDegrees += 90;
        this.rotateDegrees = this.rotateDegrees % 360;
    }
}
