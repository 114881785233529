import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { catchError, filter, map, Observable, of, switchMap, take } from 'rxjs';

import { environment } from '.././../environments/environment'
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../auth-module/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { HttpClientExtensions } from '../utils/http-client-extensions';
import { ConfigExtensions } from '../utils/config-extensions';
const helperJWT = new JwtHelperService();

const TOKEN_KEY = environment.config.tokenKey;

@Injectable({
	providedIn: 'root'
})
export class LoginGuard implements CanActivate {
	private authPath = environment.interop.authCore.basePath;
	private refreshLogin = environment.interop.authCore.user.refreshLogin;

	constructor(public authService: AuthService, public router: Router, private httpClient: HttpClient) { }

	isProduction() {
        return ConfigExtensions.isProductionWithLimitedFeatures();
	}

	navigateToDashboard() {
		if (this.isProduction()) {
			this.router.navigateByUrl('/dashboard/solicitari', { replaceUrl: true });
		}
		else {
			this.router.navigateByUrl('/dashboard', { replaceUrl: true });
		}
	}

	canActivate(): Observable<boolean> {
		const token = localStorage.getItem(TOKEN_KEY);

		if (token == null || token == '') {
			return of(true);
		} else {
			const isExpired = helperJWT.isTokenExpired(token);

			if (isExpired) {
				return this.httpClient.post<{ data: any }>(this.authPath + this.refreshLogin, { token }, { headers: HttpClientExtensions.skipBearerInterceptor() })
					.pipe(
						switchMap((res: any) => {
							let response = (typeof res.status_code !== 'undefined' ? res : res.error)
							if (typeof response.status_code !== 'undefined') {
								if (response.status_code == 200 && typeof response.data !== 'undefined') {
									console.error('token refreshed ok');
									localStorage.setItem(TOKEN_KEY, response.data.token);

									this.navigateToDashboard();

									return of(false);
								}
							}

							return of(true);
						}),
						catchError((res: any) => {
							console.error('token refresh error:', res.error);
							return of(true);
						})
					);
			} else {
				const decodedToken = helperJWT.decodeToken(token);
				const issuerUrl = new URL(decodedToken.iss);
				const hostname = issuerUrl.hostname;

				if (hostname !== environment.config.fromTokenValid) {
					return of(true);
				} else {
					console.log('token ok, navigating to dashboard...')
					this.navigateToDashboard();
					return of(false);
				}
			}
		}
	}
}
