import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ICountryOption, countriesList } from '../../shared/countries-list';
import { AuthService } from 'src/app/auth-module/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';

import { PhoneNumberUtil } from 'google-libphonenumber';
import { finalize } from 'rxjs';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Component({
    selector: 'app-solicitare',
    templateUrl: './solicitare.component.html',
    styleUrls: ['./solicitare.component.scss']
})
export class SolicitareComponent implements OnInit {
    @Input() showSolicitare: boolean = false;

    @Output() closeEvent = new EventEmitter<void>();
    @Output() showSolicitareChange = new EventEmitter<boolean>();

    suportDialogRef: MatDialogRef<any> | undefined;
    form: FormGroup = new FormGroup({
        nume: new FormControl(''),
        prenume: new FormControl(''),
        adresa: new FormControl(''),
        telefon: new FormControl(''),
        email: new FormControl(''),
        subiect: new FormControl(''),
        observatii: new FormControl(''),
        files: new FormControl(''),
        prelucrareDate: new FormControl(''),
    })

    isDashboard: boolean = false;

    optionRom: ICountryOption = {
        name: 'Romania',
        flag: '🇷🇴',
        code: 'RO',
        dial_code: '+40',
    };

    currentOption = this.optionRom;
    options: ICountryOption[] = countriesList;

    changeCountry(option: ICountryOption) {
        this.currentOption = option;
    }

    uploading: any = {
        documente_fotografii: false
    }

    files: any = {
        documente_fotografii: []
    }

    env: any = environment

    // general error
    errorTitle: string = environment.config.customNotifications.headers.error
    errorIcon: string = environment.config.customNotifications.icons.error
    errorType: string = environment.config.customNotifications.icons.error
    // general success alert
    successTitle: string = environment.config.customNotifications.headers.successForm
    successIcon: string = environment.config.customNotifications.icons.success
    successType: string = environment.config.customNotifications.icons.success

    phone_no: string = '';

    id_ticket: null | number = null;

    public loading = false;

    constructor(
        private formsBuilder: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private notificationService: NotificationService,
        public toastr: ToastrService
    ) { }

    ngOnInit(): void {
        this.isDashboard = this.router.url.startsWith('/dashboard');
        console.log(this.isDashboard);
        this.initSolicitareForm();
    }

    initSolicitareForm() {
        this.form = this.formsBuilder.group({
            nume: ['', !this.isDashboardRoute() ? [
                Validators.minLength(environment.config.validatorsAccrossApp.minStringLength),
                Validators.maxLength(environment.config.validatorsAccrossApp.maxStringLength),
                Validators.required
            ] : []],
            prenume: ['', !this.isDashboardRoute() ? [
                Validators.minLength(environment.config.validatorsAccrossApp.minStringLength),
                Validators.maxLength(environment.config.validatorsAccrossApp.maxStringLength),
                Validators.required
            ] : []],
            adresa: ['', [Validators.required]],
            telefon: ['', !this.isDashboardRoute() ? [Validators.required] : []],
            email: ['', !this.isDashboardRoute() ? [
                Validators.pattern(environment.config.emailRegex),
                Validators.maxLength(environment.config.validatorsAccrossApp.emailMaxLength),
                Validators.required,
                Validators.email,
            ] : []],
            subiect: ['', [Validators.required]],
            observatii: ['', [Validators.required]],
            files: [[]],
            prelucrareDate: [false, [Validators.requiredTrue]],
        })
    }

    closeDialog() {
        this.closeEvent.emit();
    }

    toggleShowSolicitare() {
        this.showSolicitare = !this.showSolicitare;
        this.showSolicitareChange.emit(this.showSolicitare);
    }

    isDashboardRoute() {
        return this.isDashboard;
    }

    submitForm() {
        console.log('Submit Form called');
        this.loading = true;
        let phone, data, sendRequest;
        const filesToSend = this.form.get('files')?.value || [];
        data = { ...this.form.value, files: { documente_fotografii: filesToSend } };

        if (!this.isDashboardRoute()) {
            const teletelefonInput = this.currentOption.dial_code + this.form.value.telefon;
            const telefon = phoneNumberUtil.parseAndKeepRawInput(teletelefonInput)
            let regionCodeLower = '';
            let nationalNumberString = '';
            if (telefon) {
                const regionCode = phoneNumberUtil.getRegionCodeForNumber(telefon);
                if (regionCode) {
                    regionCodeLower = (regionCode as string).toLowerCase();
                }

                const nationalNumber = telefon.getNationalNumber();
                if (nationalNumber) {
                    nationalNumberString = nationalNumber.toString();
                }
            }

            phone = {
                dialCode: '',
                internationalNumber: teletelefonInput,
                isoCode: regionCodeLower,
                nationalNumber: nationalNumberString
            }

            phone.dialCode = teletelefonInput.replace(phone.nationalNumber, '');
            phone.nationalNumber = (phone.isoCode == 'ro' ? '0' + phone.nationalNumber : phone.nationalNumber);

            data = { ...data, telefon: phone.internationalNumber };
            sendRequest = this.authService.sendSolicitare(data);
        } else {
            sendRequest = this.authService.sendSolicitareInsideAcc(data);
        }

        sendRequest.pipe(finalize(() => this.loading = false))
            .subscribe({
                next: (res: any) => {
                    let response = (typeof res.status_code !== 'undefined' ? res : res.error)
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            this.loading = false;

                            this.notificationService.warningSwal(this.successTitle, 'Solicitarea dvs cu numărul ' + response.data.reg_number + '/' + response.data.reg_date + ' a fost trimisă cu succes.', this.successIcon);

                            this.id_ticket = response.data.id;
                            this.phone_no = response.data.phone_no;
                            this.closeDialog();
                        }
                    } else {
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        this.loading = false;
                        this.notificationService.warningSwal(this.errorTitle, errorMessage, this.errorIcon);
                    }
                },
                error: (res: any) => {
                    this.loading = false;
                    this.onHttpError(res.error);
                    return false;
                }
            });
    }

    addFile(event: any, key: 'documente_fotografii') {
        const inputElement = event.target as HTMLInputElement;
        if (!inputElement.files) {
            return;
        }

        const files = inputElement.files;

        // Iterate through the selected files and append them to the 'files' array in the form
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            this.uploading[key] = true;

            const formData = new FormData();
            formData.append('file', file, file.name);

            this.authService.fileUpload(formData)
                .subscribe({
                    next: (result: any) => {
                        let response = (typeof result.status_code !== 'undefined' ? result : result.error);
                        if (typeof response.status_code !== 'undefined') {
                            if (response.status_code == 200 && typeof response.data !== 'undefined') {
                                // Handle the uploaded file by adding it to the 'files' array in the form
                                const currentFiles = this.form.get('files')?.value || [];
                                currentFiles.push(result.data);
                                this.form.get('files')?.setValue(currentFiles);

                                this.uploading[key] = false;

                                this.openToast({
                                    title: this.successTitle,
                                    message: 'Fișierul dvs. a fost adăugat cu succes.',
                                    type: this.successIcon
                                });
                            }
                        } else {
                            // Handle error cases here
                            let errorMessage = environment.config.customNotifications.generalMessages.fileErrorMsg;
                            this.notificationService.warningSwal(this.errorTitle, errorMessage, this.errorIcon);
                            this.loading = false;
                            this.uploading[key] = false;
                        }
                    },
                    error: (result: any) => {
                        this.loading = false;
                        this.uploading[key] = false;
                        this.onHttpError(result.error);
                    }
                });
        }
    }

    removeFile(index: number) {
        const currentFiles = this.form.get('files')?.value || [];
        currentFiles.splice(index, 1);
        this.form.get('files')?.setValue(currentFiles);
    }

    openToast(object: any) {
        const { type, message, title } = object;

        let options = this.toastr.toastrConfig;
        options.progressBar = true;
        options.preventDuplicates = true;
        options.closeButton = true;

        const inserted = this.toastr.show(message, title, options, options.iconClasses[type]
        );
    }

    onHttpError(res: any, showErrorsInToast: boolean = false) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                if (showErrorsInToast) {
                    this.openToast({ title: this.errorTitle, message: msg, type: this.errorType });
                }
                else {
                    this.notificationService.warningSwal(this.errorTitle, msg, this.errorIcon);
                }
            });
        }
        else {
            let errorMessage = environment.config.customNotifications.generalMessages.error;
            this.notificationService.warningSwal(this.errorTitle, errorMessage, this.errorIcon);
        }
    }
}
