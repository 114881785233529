<div class="utils">
  <div class="welcome-card">
    <div class="welcome-card_header">
      <img src="assets/images/Group 40.svg" alt="info" />
      <div class="welcome-card_header-headline">
        <h2>Informații utile</h2>
        <h4>Portal Primăria Sector 4</h4>
      </div>
    </div>
  </div>

  <app-announcements [slug]="pageSlugAnnouncements"></app-announcements>

  <div class="utils-card" *ngIf="utilsList.length > 0">
    <div class="utils-card-item-container" *ngFor="let util of utilsList">
      <div class="utils-card-item">
        <span class="item-text">{{ util.name }}</span>
        <button
          class="utils-button"
          mat-stroked-button
          color="primary"
          (click)="openUtilsModal(util)"
        >
          <div class="button-content">
            <img src="assets/icons/view.svg" alt="">
            <span class="button-text">Vizualizați</span>
          </div>
        </button>
      </div>
      <mat-divider></mat-divider>
    </div>
    <!-- <div class="utils-card-item-container">
      <div class="utils-card-item">
        <span class="item-text">Regulament protectia mediului</span>
        <button
          class="utils-button"
          mat-stroked-button
          color="primary"
        >
          <div class="button-content">
            <img src="assets/icons/view.svg" alt="">
            <span class="button-text">Vizualizați</span>
          </div>
        </button>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="utils-card-item-container">
      <div class="utils-card-item">
        <span class="item-text">Telefoane utile Primaria Sector 4</span>
        <button
          class="utils-button"
          mat-stroked-button
          color="primary"
        >
          <div class="button-content">
            <img src="assets/icons/view.svg" alt="">
            <span class="button-text">Vizualizați</span>
          </div>
        </button>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="utils-card-item-container">
      <div class="utils-card-item">
        <span class="item-text">Program de audiente Primaria Sector 4</span>
        <button
          class="utils-button"
          mat-stroked-button
          color="primary"
        >
          <div class="button-content">
            <img src="assets/icons/view.svg" alt="">
            <span class="button-text">Vizualizați</span>
          </div>
        </button>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="utils-card-item-container">
      <div class="utils-card-item">
        <span class="item-text">Program de lucru cu publicul Primaria Sector 4</span>
        <button
          class="utils-button"
          mat-stroked-button
          color="primary"
        >
          <div class="button-content">
            <img src="assets/icons/view.svg" alt="">
            <span class="button-text">Vizualizați</span>
          </div>
        </button>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="utils-card-item-container">
      <div class="utils-card-item">
        <span class="item-text">Audiente si Program de lucru Directia Mobilitate Urbana</span>
        <button
          class="utils-button"
          mat-stroked-button
          color="primary"
        >
          <div class="button-content">
            <img src="assets/icons/view.svg" alt="">
            <span class="button-text">Vizualizați</span>
          </div>
        </button>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="utils-card-item-container">
      <div class="utils-card-item">
        <span class="item-text">Regulament Parcari Resedinta</span>
        <button
          class="utils-button"
          mat-stroked-button
          color="primary"
        >
          <div class="button-content">
            <img src="assets/icons/view.svg" alt="">
            <span class="button-text">Vizualizați</span>
          </div>
        </button>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="utils-card-item-container">
      <div class="utils-card-item">
        <span class="item-text">Regulament Parcari Publice</span>
        <button
          class="utils-button"
          mat-stroked-button
          color="primary"
        >
          <div class="button-content">
            <img src="assets/icons/view.svg" alt="">
            <span class="button-text">Vizualizați</span>
          </div>
        </button>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="utils-card-item-container">
      <div class="utils-card-item">
        <span class="item-text">Regulament Blocari / Deblocari - Autovehicule / Vehicule</span>
        <button
          class="utils-button"
          mat-stroked-button
          color="primary"
        >
          <div class="button-content">
            <img src="assets/icons/view.svg" alt="">
            <span class="button-text">Vizualizați</span>
          </div>
        </button>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="utils-card-item-container">
      <div class="utils-card-item">
        <span class="item-text">Planul de aparare in cazul producerii unei situatii de urgenta specifice provocate de cutremure si / sau alunecari de teren</span>
        <button
          class="utils-button"
          mat-stroked-button
          color="primary"
        >
          <div class="button-content">
            <img src="assets/icons/view.svg" alt="">
            <span class="button-text">Vizualizați</span>
          </div>
        </button>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="utils-card-item-container">
      <div class="utils-card-item">
        <span class="item-text">Legea nr 50/1991 privind autorizarea executării lucrărilor de construcții, republicată cu modificările și completările ulterioare</span>
        <button
          class="utils-button"
          mat-stroked-button
          color="primary"
        >
          <div class="button-content">
            <img src="assets/icons/view.svg" alt="">
            <span class="button-text">Vizualizați</span>
          </div>
        </button>
      </div>
      <mat-divider></mat-divider>
    </div> -->
  </div>
</div>
