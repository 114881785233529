import { ConfigExtensions } from 'src/app/utils/config-extensions';
import { environment } from 'src/environments/environment';


export enum UrbanismNavigationState {
    Init = 'init',
    Add = 'add',
    Upload = 'upload',
}

const SOLICITARI_URBANISM_LIST_DEV = [
    {
        title: 'CERERI PENTRU EMITEREA DOCUMENTAȚIILOR DE URBANISM',
        subitems: [
            {
                name: 'Cerere pentru emiterea certificatului de urbanism',
                slug: 'emitere-certificat-urbanism'
            },
            {
                name: 'Cerere pentru emiterea certificatului de urbanism - Rețele tehnico-edilitare / branșamente / racorduri',
                slug: 'emitere-cert-urb-retele-tehnico-edilitare'
            },
            {
                name: 'Cerere pentru emiterea certificatului de urbanism - Mijloace Publicitare',
                slug: 'emitere-cert-urb-mijloace-publicitare'
            },
            {
                name: 'Cerere pentru prelungirea valabilității certificatului de urbanism',
                slug: 'prelungire-valabilitate-urbanism'
            },
            {
                name: 'Cerere pentru emiterea autorizației de construire / desființare',
                slug: 'autorizatie-construire-desfiintare'
            },
            {
                name: 'Cerere pentru emiterea Autorizației de Construire / Desființare - Rețele tehnico-edilitare / branșamente / racorduri',
                slug: 'construction-dem-retele-tehnico-edilitare'
            },
            {
                name: 'Cerere pentru emiterea Autorizației de Construire / Desființare - Mijloace Publicitare',
                slug: 'construction-dem-mijloace-publicitare'
            },
            {
                name: 'Cerere pentru dispoziție de șantier',
                slug: 'dispozitie-de-santier'
            },
            {
                name: 'Cerere pentru prelungirea valabilității autorizației de construire / desființare',
                slug: 'prelungire-valabilitate-autorizatie'
            },
            {
                name: 'Cerere pentru regularizarea taxei datorate ca urmare a declarării valorii reale la terminarea lucrărilor',
                slug: 'regularizare-taxa-reala'
            },
            {
                name: 'Cerere de participare in comisia de recepție la terminarea lucrărilor de construcții',
                slug: 'solicitare-participare-comisie'
            },
            {
                name: 'Cerere pentru Emiterea CERTIFICATULUI DE ATESTARE A EDIFICĂRII CONSTRUCȚIEI',
                slug: 'admitere-atestare-edificare'
            },
            {
                name: 'Cerere pentru emitere acord / autorizație administrator drum',
                slug: 'emitere-acord-admin-drum'
            },
            {
                name: 'Cerere adresă schimbare destinație',  // Cerere pentru emiterea certificatului de urbanism (SCHIMBARE DE DESTINAȚIE)
                slug: 'emitere-certificat-urbanism-schimbare-domiciliu'
            },
            {
                name: 'Adrese/Altele',
                slug: 'adrese-altele'
            },
        ]
    },
    {
        title: 'AVIZE',
        subitems: [
            {
                name: 'Aviz Primar',
                slug: 'aviz-primar'
            },
            {
                name: 'Cerere pentru emiterea Avizului de Amplasare',
                slug: 'emitere-aviz-amplasare'
            },
            {
                name: 'Cerere pentru avizarea studiului de amplasare a mijloacelor publicitare',
                slug: 'avizare-studiu-amplasare-mijloace-publicitare'
            },
        ]
    },
    {
        title: 'PLAN URBANISTIC DE DETALIU (PUD)',
        subitems: [
            {
                name: 'Cerere pentru avizarea / aprobarea documentației de urbanism PUD (plan Urbanistic de Detaliu)',
                slug: 'avizare-aprobare-documentatie-urbanism'
            },
            {
                name: 'Cerere pentru emiterea avizului de supraetajare a construcțiilor cu 20%',
                slug: 'emitere-aviz-supraetajare'
            }
        ]
    },
    {
        title: 'INFORMĂRI / COMUNICĂRI',
        subitems: [
            {
                name: 'Comunicare privind incepere execuție lucrări',
                slug: 'inceperea-executiei-lucrarilor'
            },
            {
                name: 'Comunicare privind incheiere execuție lucrări',
                slug: 'incetarea-executiei-lucrarilor'
            }
        ]
    }
]

const SOLICITARI_URBANISM_LIST_PROD = [
    {
        title: 'CERERI PENTRU EMITEREA DOCUMENTAȚIILOR DE URBANISM',
        subitems: [
            {
                name: 'Cerere pentru emiterea certificatului de urbanism',
                slug: 'emitere-certificat-urbanism'
            },
            {
                name: 'Cerere pentru emiterea certificatului de urbanism - Rețele tehnico-edilitare / branșamente / racorduri',
                slug: 'emitere-cert-urb-retele-tehnico-edilitare'
            },
            {
                name: 'Cerere pentru emiterea certificatului de urbanism - Mijloace Publicitare',
                slug: 'emitere-cert-urb-mijloace-publicitare'
            },
            {
                name: 'Cerere pentru prelungirea valabilității certificatului de urbanism',
                slug: 'prelungire-valabilitate-urbanism'
            },
            {
                name: 'Cerere pentru emiterea autorizației de construire / desființare',
                slug: 'autorizatie-construire-desfiintare'
            },
            {
                name: 'Cerere pentru emiterea Autorizației de Construire / Desființare - Rețele tehnico-edilitare / branșamente / racorduri',
                slug: 'construction-dem-retele-tehnico-edilitare'
            },
            {
                name: 'Cerere pentru emiterea Autorizației de Construire / Desființare - Mijloace Publicitare',
                slug: 'construction-dem-mijloace-publicitare'
            },
            {
                name: 'Cerere pentru dispoziție de șantier',
                slug: 'dispozitie-de-santier'
            },
            {
                name: 'Cerere pentru prelungirea valabilității autorizației de construire / desființare',
                slug: 'prelungire-valabilitate-autorizatie'
            },
            {
                name: 'Cerere pentru regularizarea taxei datorate ca urmare a declarării valorii reale la terminarea lucrărilor',
                slug: 'regularizare-taxa-reala'
            },
            {
                name: 'Cerere de participare in comisia de recepție la terminarea lucrărilor de construcții',
                slug: 'solicitare-participare-comisie'
            },
            {
                name: 'Cerere pentru Emiterea CERTIFICATULUI DE ATESTARE A EDIFICĂRII CONSTRUCȚIEI',
                slug: 'admitere-atestare-edificare'
            },
            {
                name: 'Cerere pentru emitere acord / autorizație administrator drum',
                slug: 'emitere-acord-admin-drum'
            },
            {
                name: 'Cerere adresă schimbare destinație',  // Cerere pentru emiterea certificatului de urbanism (SCHIMBARE DE DESTINAȚIE)
                slug: 'emitere-certificat-urbanism-schimbare-domiciliu'
            },
            {
                name: 'Adrese/Altele',
                slug: 'adrese-altele'
            },
        ]
    },
    {
        title: 'AVIZE',
        subitems: [
            {
                name: 'Aviz Primar',
                slug: 'aviz-primar'
            },
            {
                name: 'Cerere pentru emiterea Avizului de Amplasare',
                slug: 'emitere-aviz-amplasare'
            },
            {
                name: 'Cerere pentru avizarea studiului de amplasare a mijloacelor publicitare',
                slug: 'avizare-studiu-amplasare-mijloace-publicitare'
            },
        ]
    },
    {
        title: 'PLAN URBANISTIC DE DETALIU (PUD)',
        subitems: [
            {
                name: 'Cerere pentru avizarea / aprobarea documentației de urbanism PUD (plan Urbanistic de Detaliu)',
                slug: 'avizare-aprobare-documentatie-urbanism'
            },
            {
                name: 'Cerere pentru emiterea avizului de supraetajare a construcțiilor cu 20%',
                slug: 'emitere-aviz-supraetajare'
            }
        ]
    },
    {
        title: 'INFORMĂRI / COMUNICĂRI',
        subitems: [
            {
                name: 'Comunicare privind incepere execuție lucrări',
                slug: 'inceperea-executiei-lucrarilor'
            },
            {
                name: 'Comunicare privind incheiere execuție lucrări',
                slug: 'incetarea-executiei-lucrarilor'
            }
        ]
    }
]


export const SOLICITARI_URBANISM_LIST = ConfigExtensions.isProductionWithLimitedFeatures() ? SOLICITARI_URBANISM_LIST_PROD : SOLICITARI_URBANISM_LIST_DEV;