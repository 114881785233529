<div class="settings-card">
    <div class="settings-card__header">
        <img src="assets/images/Group 24.svg" alt="" />
        <div class="settings-card__header-headline">
            <h2>Documentele dumneavoastră</h2>
            <h4>{{ platformName }}</h4>
        </div>
    </div>
</div>

<div class="requests-page">
    <div class="content-box">
        <div class="row">
            <div class="col-sm-12">
                <div class="section position-relative">
                    <div class="section-top">
                        <h3 class="section-title">Documente Mele</h3>
                        <div class="section-top-actions">
                            <button type="button" class="btn btn-sm btn-info pull-right ms-1" (click)="rerenderDT(false)">
                                <i class="fa-solid fa-arrows-rotate"></i>
                            </button>
                        </div>
                    </div>
                    <div class="table-container">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover w-100"></table>
                    </div>
                    <div class="section-spinner position-absolute" *ngIf="isLoading">
                        <div class="spinner position-absolute top-50 start-50 translate-middle">
                            <mat-spinner></mat-spinner>
                            <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
