import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentsComponent } from './payments.component';
import { MaterialModule } from 'src/app/material.module';
import { PaymentsModalComponent } from './payments-modal/payments-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AnnouncementsModule } from 'src/app/shared/announcements/announcements.module';

@NgModule({
  declarations: [PaymentsComponent, PaymentsModalComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, AnnouncementsModule],
})
export class PaymentsModule { }
