import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { HttpClientExtensions } from '../../utils/http-client-extensions';

const helperJWT = new JwtHelperService();
const TOKEN_KEY = environment.config.tokenKey;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    token = '';

    private authPath = environment.interop.authCore.basePath;
    private refreshLogin = environment.interop.authCore.user.refreshLogin;
    private loginPath = environment.interop.authCore.user.loginUrl;
    private resendCodeUrl = environment.interop.authCore.user.resendCode;
    private confirmCodeUrl = environment.interop.authCore.user.confirmCode;
    private checkEmailPasswordResetUrl = environment.interop.authCore.user.checkEmailPasswordReset;
    private resendCodePasswordResetUrl = environment.interop.authCore.user.resendCodePasswordReset;
    private verifyCodePasswordResetUrl = environment.interop.authCore.user.verifyCodePasswordReset;
    private updatePasswordResetUrl = environment.interop.authCore.user.updatePasswordReset;
    private fileUploadRegisterUrl = environment.interop.authCore.user.fileUploadRegister;
    private registerAccountUrl = environment.interop.authCore.user.registerAccount;
    private registerAccountInrolatUrl = environment.interop.authCore.user.registerAccountInrolat;

    private sendSolicitareUrl = environment.interop.authCore.user.sendSolicitare;
    private sendSolicitareInsideUrl = environment.interop.authCore.user.sendSolicitareInsideAcc;
    private sendFormContactUrl = environment.interop.authCore.contact;

    private logoutPath = environment.interop.authCore.user.logoutUrl;
    private registerCheckCNPPath = environment.interop.authCore.user.registerCheckCNP;
    private checkPinRegisterUrl = environment.interop.authCore.user.checkPinRegister;
    private resetPINCodeInrolatAccountUrl = environment.interop.authCore.user.resetPINCodeInrolatAccount;

    // ocr
    private processCiUrl = environment.interop.authCore.ocr.processCi;
    private processCertUrl = environment.interop.authCore.ocr.processCert;

    // platform elements
    private urbanismPath = environment.interop.urbanismCore.basePath;
    private faqPath = environment.interop.urbanismCore.platform.faq;
    private contentPath = environment.interop.urbanismCore.platform.content;

    private announcesPath = environment.interop.authCore.announces;

    private listArticles = environment.interop.authCore.article.list;
    private findArticle = environment.interop.authCore.article.find;

    constructor(private http: HttpClient) {
        //this.loadToken();
    }

    setToken(token: string) {
        this.token = token
    }

    public setAuthenticatedNext(bool: boolean): void {
        this.isAuthenticated$.next(bool);
    }

    loadToken() {
        const token = this.getAuthToken();
        if (token) {
            const isExpired = helperJWT.isTokenExpired(token);
            if (isExpired) {
                this.refreshToken(token).subscribe(
                    (res: any) => {
                        try {
                            let response = (typeof res.status_code !== 'undefined' ? res : res.error);
                            if (typeof response.status_code !== 'undefined') {
                                if (response.status_code == 200 && typeof response.data.token !== 'undefined') {
                                    // everything ok now, we wrap it up now
                                    this.setToken(response.data.token)
                                    this.setAuthToken(response.data.token);
                                    this.isAuthenticated$.next(true);
                                } else {
                                    this.isAuthenticated$.next(false);
                                }

                            } else {
                                this.isAuthenticated$.next(false);
                            }
                        }
                        catch {
                            this.isAuthenticated$.next(false);
                        }

                    },
                    (error: any) => {
                        // Handle error here
                        console.error('Error refreshing token:', error);
                        this.isAuthenticated$.next(false);
                    });
            } else {
                this.isAuthenticated$.next(true);
            }

        } else {
            this.isAuthenticated$.next(false);
        }
    }

    refreshToken(token: string) {
        let obj = {
            token
        }

        return this.http.post<{ data: any }>(this.authPath + this.refreshLogin, obj, { headers: HttpClientExtensions.skipBearerInterceptor() });
    }

    login(data: { email: string, cnp?: number, password: string }) {
        return this.http.post(`${this.authPath + this.loginPath}`, data, { headers: HttpClientExtensions.skipBearerInterceptor() });
    }

    resendCode(data: any) {
        return this.http.post<{ data: any }>(this.authPath + this.resendCodeUrl, data);
    }

    confirmCode(data: any) {
        return this.http.post<{ data: any }>(this.authPath + this.confirmCodeUrl, data);
    }

    checkEmailPasswordReset(data: any) {
        return this.http.post<{ data: any }>(this.authPath + this.checkEmailPasswordResetUrl, data);
    }

    resendCodePasswordReset(data: any) {
        return this.http.post<{ data: any }>(this.authPath + this.resendCodePasswordResetUrl, data);
    }

    verifyCodePasswordReset(data: any) {
        return this.http.post<{ data: any }>(this.authPath + this.verifyCodePasswordResetUrl, data);
    }

    updatePasswordReset(data: any) {
        return this.http.post<{ data: any }>(this.authPath + this.updatePasswordResetUrl, data);
    }

    fileUpload(formData: FormData) {
        return this.http.post<{ data: any }>(this.authPath + this.fileUploadRegisterUrl, formData);
    }

    processOcr(filename: string, key: 'file_ci' | 'file_cui') {
        var headers = HttpClientExtensions.skipBearerInterceptor();
        headers.set('Ocp-Apim-Subscription-Key', 'd095dc8604e947b6a2deeb694f5a2389');
        const processUrl = key == 'file_ci' ? this.processCiUrl : this.processCertUrl
        return this.http.post<{ data: any }>(this.authPath + processUrl, { file: filename }, { headers });
    }

    registerAccount(formData: FormData) {
        return this.http.post<{ data: any }>(this.authPath + this.registerAccountUrl, formData, { headers: HttpClientExtensions.skipBearerInterceptor() });
    }

    registerAccountInrolat(formData: FormData) {
        return this.http.post<{ data: any }>(this.authPath + this.registerAccountInrolatUrl, formData, { headers: HttpClientExtensions.skipBearerInterceptor() });
    }

    logout() {
        localStorage.removeItem(TOKEN_KEY);
        this.isAuthenticated$.next(false);

        // taxe si impozite makes no calls to the backend on logout, only cleaning of the localStorage token
        // return this.http.post(`${this.authPath + this.logoutPath}`);
    }

    sendSolicitare(formData: FormData) {
        return this.http.post<{ data: any }>(this.authPath + this.sendSolicitareUrl, formData);
    }

    sendSolicitareInsideAcc(formData: FormData) {
        return this.http.post<{ data: any }>(this.authPath + this.sendSolicitareInsideUrl, formData);
    }

    sendFormContact(formData: FormData) {
        return this.http.post<{ data: any }>(this.authPath + this.sendFormContactUrl, formData);
    }

    checkPinRegister(data: any) {
        return this.http.post<{ data: any }>(this.authPath + this.checkPinRegisterUrl, data);
    }

    checkUserCNP(cnp: number) {
        return this.http.post(`${this.authPath + this.registerCheckCNPPath}`, cnp);
    }

    resetPINCodeInrolatAccount(data: any) {
        return this.http.post<{ data: any }>(this.authPath + this.resetPINCodeInrolatAccountUrl, data);
    }

    /* Utility functions */

    isAuthenticated(): boolean {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) return !helperJWT.isTokenExpired(token);

        return false;
    }

    getAuthToken(): string | null {
        return localStorage.getItem(TOKEN_KEY);
    }

    setAuthToken(token: string): void {
        localStorage.setItem(TOKEN_KEY, token);
    }

    clearStorage() {
        localStorage.clear();
    }

    /* Platform functions */

    getFAQ(slug: string) {
        return this.http.get(`${this.authPath + this.faqPath}${slug}`);
    }

    getContent(slug: string) {
        return this.http.get(`${this.authPath + this.contentPath}${slug}`);
    }

    getAnnounces(slug: string) {
        return this.http.get(`${this.authPath + this.announcesPath}${slug}`);
    }

    getArticles(page: number) {
        return this.http.get(`${this.authPath + this.listArticles}?page=${page}`);
    }

    getArticleDetails(id: string) {
        return this.http.get(`${this.authPath + this.findArticle}${id}`)
    }
}
