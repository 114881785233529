import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsComponent } from './settings.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BeneficiarComponent } from './beneficiar/beneficiar.component';
import { TermeniConditiiModalModule } from 'src/app/shared/termeni-conditii-modal/termeni-conditii-modal.module';


@NgModule({
  declarations: [SettingsComponent, BeneficiarComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TermeniConditiiModalModule,

  ],
})
export class SettingsModule {}
