<div class="payment">
  <!-- Closing Card Button -->
  <button
    mat-flat-button
    class="payment-button payment-button-three"
    mat-dialog-close
  >
    <mat-icon>close</mat-icon>
    Închide
  </button>
  <!-- Main Card -->
  <mat-card class="payment-card">
    <!-- Header -->
    <mat-card-header class="payment-card__header">
      <mat-card-title class="payment-card__title"
        >Achită taxe nedebitate</mat-card-title
      >
      <div class="payment-card__info">
        <mat-icon>info</mat-icon>
        <mat-card-subtitle class="payment-card__info-message">
          Plata taxelor nedebitate poate fi achitată doar de către persoanele ce
          au rol fiscal în cadrul DGITL Sector 4.
        </mat-card-subtitle>
      </div>
    </mat-card-header>

    <!-- Content -->
    <mat-card-content class="payment-card__content">
      <form
        class="payment-card-form"
        [formGroup]="formPayment"
        (ngSubmit)="submitForm()"
      >
        <!-- Taxes Type Selection -->
        <mat-form-field appearance="fill">
          <mat-label>Alegeți</mat-label>
          <select matNativeControl formControlName="paymentType">
            <!-- <option value="" selected></option> -->
            <option *ngFor="let type of paymentTypes" [value]="type.value">
              {{ type.description }}
            </option>
          </select>
          <mat-hint>Selectați tipul de taxă</mat-hint>
          <mat-error
            *ngIf="
              !formPayment.controls['paymentType'].valid &&
              formPayment.controls['paymentType'].touched
            "
          >
            Câmp obligatoriu
          </mat-error>
        </mat-form-field>
        <!-- Payment Value Input -->
        <mat-form-field appearance="fill">
          <mat-label>Suma de achitat</mat-label>
          <input
            type="text"
            matInput
            placeholder="Suma de achitat"
            formControlName="paymentSum"
          />
          <mat-hint>Introduceți suma de achitat</mat-hint>
          <mat-error
            *ngIf="
              !formPayment.controls['paymentSum'].valid &&
              formPayment.controls['paymentSum'].touched
            "
          >
            Câmp obligatoriu
          </mat-error>
        </mat-form-field>
        <!-- Last Name Input -->
        <mat-form-field appearance="fill">
          <mat-label>Nume</mat-label>
          <input
            type="text"
            matInput
            placeholder="Nume"
            formControlName="lastName"
          />
          <mat-hint>Introduceți numele de familie</mat-hint>
          <mat-error
            *ngIf="
              !formPayment.controls['lastName'].valid &&
              formPayment.controls['lastName'].touched
            "
          >
            Câmp obligatoriu
          </mat-error>
        </mat-form-field>
        <!-- First Name Input -->
        <mat-form-field appearance="fill">
          <mat-label>Prenume</mat-label>
          <input
            type="text"
            matInput
            placeholder="Prenume"
            formControlName="firstName"
          />
          <mat-hint>Introduceți prenumele</mat-hint>
          <mat-error
            *ngIf="
              !formPayment.controls['firstName'].valid &&
              formPayment.controls['firstName'].touched
            "
          >
            Câmp obligatoriu
          </mat-error>
        </mat-form-field>
        <!-- CNP_CUI Input -->
        <mat-form-field appearance="fill">
          <mat-label>CNP sau CUI</mat-label>
          <input
            type="number"
            matInput
            placeholder="CNP|CUI"
            formControlName="cnp_cui"
          />
          <mat-hint>Introduceți CNP-ul sau CUI-ul</mat-hint>
        </mat-form-field>
      </form>
    </mat-card-content>

    <!-- Divider -->
    <mat-divider></mat-divider>

    <!-- Footer -->
    <mat-card-content class="payment-card__footer">
      <button
        class="payment-button"
        mat-flat-button
        color="primary"
        [disabled]="!formPayment.valid"
        (click)="submitForm()"
      >
        Continuați
      </button>
    </mat-card-content>
  </mat-card>
</div>
