<div *ngIf="!showSolicitare" class="suport-card">
	<div class="suport-card__container w-100">
		<div class="suport-card__container-header">
			<p>Întrebări frecvente</p>
			<div>
				<button type="button" class="close" aria-label="Close" (click)="closeDialog()">
					<span class="close-icon" aria-hidden="true">&times;</span>
					<span class="close-text">&nbsp;&nbsp;ÎNCHIDE</span>
				</button>
			</div>
		</div>

		<mat-accordion>
			<ng-container *ngFor="let row of FAQList">
				<mat-expansion-panel class="suport-card__firstExpansion">
					<mat-expansion-panel-header>

						<mat-panel-title> {{row.name}}</mat-panel-title>
					</mat-expansion-panel-header>
					<div [innerHTML]="row.observations"></div>

				</mat-expansion-panel>
				<mat-divider class="divider"></mat-divider>
			</ng-container>
		</mat-accordion>
		<div class="suport-card__button">
			<button class="btn" (click)="loadSolicitare()">SUPORT TEHNIC</button>
		</div>
	</div>
</div>
<ng-container *ngIf="showSolicitare">
	<app-solicitare [showSolicitare]="showSolicitare" (showSolicitareChange)="handleShowSolicitareChange($event)" (closeEvent)="closeDialog()"></app-solicitare>
</ng-container>