import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedLinksEnum, PublicLinksEnum } from 'src/app/models';
import { ConfigExtensions } from 'src/app/utils/config-extensions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
    platformName = environment.config.platformName;
    
  // pageSlug: string = 'HOME_PAGE';
  pageSlug: string = 'section_1'; // To test local/development
    publicLinks = PublicLinksEnum;
    loggedLinks = LoggedLinksEnum;

    constructor(private router: Router) { }

  isProduction() {
      return ConfigExtensions.isProductionWithLimitedFeatures();
  }

    navigateToNewTab(route: string) {
        window.open(route, '_blank');
    }

    navigateTo(route: string) {
        this.router.navigate([route]);
    }
}
