import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth-module/services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClientExtensions } from '../utils/http-client-extensions';

const authPath = environment.interop.authCore.basePath;
const refreshLoginUrl = environment.interop.authCore.user.refreshLogin;
const loginUrl = environment.config.loginUrl;
const TOKEN_KEY = environment.config.tokenKey;

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

    constructor(private httpClient: HttpClient, private router: Router) { }  // Inject the Router service

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: any) => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    console.log('401 unauthorized. try token refresh...');
                    const token = localStorage.getItem(TOKEN_KEY);

                    if (token) {
                        this.httpClient.post<{ data: any }>(authPath + refreshLoginUrl, { token }, { headers: HttpClientExtensions.skipBearerInterceptor() })
                            .subscribe(
                                (res: any) => {
                                    let response = (typeof res.status_code !== 'undefined' ? res : res.error);
                                    if (typeof response.status_code !== 'undefined') {
                                        if (response.status_code == 200 && typeof response.data.token !== 'undefined') {
                                            // refresh ok 
                                            localStorage.setItem(TOKEN_KEY, response.data.token);
                                            const clone = request.clone({
                                                setHeaders: {
                                                    Authorization: `Bearer ${response.data.token}`
                                                }
                                            });
                                            return next.handle(clone);
                                        }
                                    }

                                    console.log('token refresh response with no status_code or no token. navigate to login...');
                                    this.router.navigateByUrl(loginUrl, { replaceUrl: true });
                                    return throwError(response);
                                },
                                (error: any) => {
                                    // Handle error here
                                    console.log('token refresh failed. navigate to login...');
                                    this.router.navigateByUrl(loginUrl, { replaceUrl: true });
                                    return throwError(error);
                                });
                    }
                    console.log('no token. navigate to login...');
                    this.router.navigateByUrl(loginUrl, { replaceUrl: true });
                    return throwError(error);
                }
                return throwError(error);
            })
        );
    }
}