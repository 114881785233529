import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private baseUrl = environment.interop.authCore.basePath;
    private getJudeteUrl = this.baseUrl + environment.interop.authCore.judete.getJudete;

    constructor(private http: HttpClient) { }

    getJudete() {
        return this.http
            .get<{ errors: boolean; data: any[]; status_code: number }>(
                this.getJudeteUrl
            )
            .pipe(
                map((response) =>
                    response.data.map((item) => ({
                        id: item.id,
                        name: item.name,
                    }))
                ),
                catchError((error) => {
                    console.error('Error in get registre:', error);
                    return throwError(error);
                })
            );
    }
}