import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/auth-module/services/auth.service';
import { Announcement } from 'src/app/models';
import { helper } from 'src/environments/helper';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
})
export class AnnouncementsComponent implements OnInit {
    @Input() slug: string = '';
    @Output() onLoaded: EventEmitter<Announcement[]> = new EventEmitter();
    announcementsList: Announcement[] = [];
    isLoading = false;
    helper = helper;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
      this.getAnnouncements();
  }

  getAnnouncements() {
    this.isLoading = true;
    this.authService
        .getAnnounces(this.slug)
        .pipe(finalize(() => {
            this.isLoading = false;
            this.onLoaded.next(this.announcementsList);
        }))
        .subscribe({
        next: (res: any) => {
                let response =
                    typeof res.status_code !== 'undefined' ? res : res.error;
                if (typeof response.status_code !== 'undefined') {
                    if (
                        response.status_code == 200 &&
                        typeof response.data !== 'undefined'
                    ) {
                        this.announcementsList = response.data;
                }
            }
        },
        error: (error) => {
            console.error(error);
        },
        });
  }
}
