import { Component } from '@angular/core';
import { DocumentsListUtile } from 'src/app/enums/documents.enum';

@Component({
    selector: 'app-utile',
    templateUrl: './utile.component.html',
    styleUrls: ['./utile.component.scss']
})
export class UtileComponent {
    dropdownItems = Object.values(DocumentsListUtile).map((type) => ({
        name: type,
    }));
}
