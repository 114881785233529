export interface Tutorial {
    link: string;
    title: string;
}

export const tutorials: Tutorial[] = [
    {
        link: 'https://youtu.be/aLTTW66wXYQ',
        title: 'Login CNP/CIF - Portal Cetatean',
    },
    {
        link: 'https://youtu.be/uLG5AmzDR0k',
        title: 'Login Email - Portal Cetatean',
    },
    {
        link: 'https://youtu.be/Vcbay2NROyg',
        title: 'Înregistrare cont nou - Portal Cetațean',
    },
    {
        link: 'https://youtu.be/tysl1Xe9FgA',
        title: 'Cont preînrolat - Înregistrare cont',
    },
    {
        link: 'https://youtu.be/75KtoDsHaRI',
        title: 'Cont preînrolat - Recuperare cod unic',
    },
    {
        link: 'https://youtu.be/WeKoStmDIcI',
        title: 'Întrebări Frecvente + Suport tehnic',
    },

    {
        link: 'https://youtu.be/oxoOL2hdwSY',
        title: 'Zonă setări - Portal cetațean- Adrese beneficiari',
    },
    {
        link: 'https://youtu.be/8vtgL2Lxrgs',
        title: 'Zona setări - Portal cetățean- Întrebări Frecvente + suport tehnic',
    },
    {
        link: 'https://youtu.be/iM5UUFpjnTo',
        title: 'Depunere cerere - Certificat Urbanism - Portal cetațean',
    },
];
