import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigExtensions } from 'src/app/utils/config-extensions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    const currentUrl = this.router.url;
    if (!currentUrl.startsWith('/dashboard/')) {
      if (this.isProduction()) {
        this.router.navigate(['/dashboard/solicitari']);
      } else {
        this.router.navigate(['/dashboard/home']);
      }
    }
  }

  isProduction() {
      return ConfigExtensions.isProductionWithLimitedFeatures();
  }
}
