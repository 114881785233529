import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { Solicitare } from '../interfaces/request.interface';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EventsService {

    private events: { [name: string]: ReplaySubject<any> };

    constructor() {
        this.events = {}
    }

    public getEvent(eventName: string): ReplaySubject<any> {
        if (!this.events[eventName]) {
            this.events[eventName] = new ReplaySubject<any>();
        }

        return this.events[eventName];
    }

    public clearEvent(eventName: string) {
        delete this.events[eventName];
    }

    public clearEvents() {
        this.events = {}
    }
}
