import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutComponent } from './dashboard-layout.component';
import { MaterialModule } from 'src/app/material.module';
import { RouterModule } from '@angular/router';
import { SettingsCardModule } from 'src/app/shared/settings-card/settings-card.module';
import { NavbarModule } from '../components/navbar/navbar.module';
import { SidenavModule } from '../components/sidenav/sidenav.module';
import { HomeModule } from 'src/app/components/home/home.module';
import { PaymentsModule } from 'src/app/components/payments/payments.module';
import { SettingsModule } from 'src/app/components/settings/settings.module';
import { UtilsModule } from 'src/app/components/utils/utils.module';
import { NotificationsModule } from 'src/app/components/notifications/notifications.module';
import { SolicitariModule } from '../../components/solicitari/solicitari.module';

@NgModule({
  declarations: [DashboardLayoutComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SettingsCardModule,
    NavbarModule,
    SidenavModule,
    HomeModule,
    PaymentsModule,
    SettingsModule,
    UtilsModule,
    NotificationsModule,
    SolicitariModule
  ],
  exports: [RouterModule],
})
export class DashboardLayoutModule { }
