import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OpenVideoComponent } from './openVideo/open-video.component';
import { DomSanitizer } from '@angular/platform-browser';
import { tutorials } from './tutorial.interface';

@Component({
	selector: 'app-video-guides',
	templateUrl: './video-guides.component.html',
	styleUrls: ['./video-guides.component.scss'],
})
export class VideoGuidesComponent {
	tutorials = tutorials;

	constructor(public dialog: MatDialog, private sanitizer: DomSanitizer) { }

	extractYouTubeVideoId(url: string): string | null {
		const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
		const match = url.match(regExp);

		if (match && match[2].length === 11) {
			return match[2];
		} else {
			return null;
		}
	}

	openTutorialModal(tutorialLink: string) {
		const videoId = this.extractYouTubeVideoId(tutorialLink);
		if (videoId) {
			this.dialog.open(OpenVideoComponent, {
				data: {
					link: this.sanitizer.bypassSecurityTrustResourceUrl(
						`https://www.youtube.com/embed/${videoId}`
					),

				},
			});
		} else {
			console.error('Could not extract video ID from URL');
		}
	}
}
