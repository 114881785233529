<div class="pages">
    <div class="pages-container">
        <!-- Welcome -->
        <div class="pages-welcome">
            <div class="pages-welcome-container">
                <img src="assets/images/LogoPS4+MB.png" alt="DGITL4" class="pages-welcome-avatar" />
                <div class="pages-welcome-title">Bine ați venit!</div>
                <div class="pages-welcome-description">
                    Autentificați-vă în cadrul platformei <br />
                    <span class="pages-welcome-bold">
                        Direcției Generale de Urbanism și Amenajarea Teritoriului</span>
                </div>
            </div>
        </div>
        <!-- Content -->
        <div class="pages-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <!-- <div class="pages-footer">Copyright © DGITL Sector 4 - 2021 - 2023</div> -->
</div>