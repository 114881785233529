<div class="noutati-container">
    <div class="noutati-welcome-card d-flex flex-column align-items-start align-self-stretch">
        <div class="noutati-welcome-card-content d-flex justify-content-between align-items-center align-self-stretch">
            <span>
                Mai jos puteți găsi o listă cu noutăți
            </span>
        </div>
    </div>

    <div class="noutati container-fluid">
        <div class="noutati-content row">
            <ng-container *ngIf="!isLoading">
                <ng-container *ngFor="let row of articleList">
                    <div  class="noutati-content-cards d-flex flex-column justify-content-end align-items-start col-lg-3 ms-1 me-1 mb-2" (click)="navigateToArticle(row.id)">
                        <div class="image" [ngStyle]="{'background-image': 'url(' + row.image_url + ')'}"></div>
                        <div class="noutati-content-cards-text d-flex flex-column justify-content-start align-items-start">
                            <span class="title">{{ (row.title.length > 33) ? (row.title | slice: 0:32) + '...' : (row.title) }}</span>
                            <span class="tag">{{ formatDate(row.last_updated) }}</span>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="!isMaxPage() && !isLoading && !disableLoadButton" class="d-flex justify-content-center col-lg-12 mt-3">
                    <button class="d-flex justify-content-center align-items-center" type="button" (click)="loadMoreArticles()">
                        AFIȘEAZĂ MAI MULTE
                    </button>
                </div>
                <div *ngIf="disableLoadButton" class="d-flex flex-column align-items-center justify-content-center col-lg-12 mt-3">
                    <mat-spinner></mat-spinner>
                    <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
                </div>
            </ng-container>
            <ng-container *ngIf="isLoading">
                <div class="spinner d-flex flex-column justify-content-center align-items-center col-lg-3">
                    <mat-spinner></mat-spinner>
                    <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>