import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth-module/services/auth.service';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { DialogComponent } from '../dialog/dialog.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    appName = environment.config.appName;
    copyrightText = environment.config.copyrightText;

    constructor(
        private authService: AuthService,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private breakpointObserver: BreakpointObserver
    ) { }


    openModal(slug: string) {
     
        let configData = {
          data: { slug: slug },
        
          width: '80%',
          height: '70%',
          minWidth: '275px',
        };
    
        const dialogRef = this.dialog.open(DialogComponent, configData);
   
        const layoutChanges = this.breakpointObserver.observe([
          '(max-width: 599px)', // xs breakpoint
          '(min-width: 600px)'  // sm breakpoint
        ]);
    
        const layoutChangeSubscription = layoutChanges.subscribe(result => {
          if (result.matches) {
            if (result.breakpoints['(max-width: 599px)']) {
              // Smaller devices          
              dialogRef.updateSize('95%', '80%')
            } else {
              // Larger devices
              dialogRef.updateSize('80%', '70%')
            }
          }
        });

        dialogRef.afterClosed().subscribe(()=>{
            layoutChangeSubscription.unsubscribe()
        })
      }
    
    navigateToNewTab(route: string, newTab: boolean = true) {
        const target = newTab ? '_blank' : '_self';
        window.open(route, target);
    }
}
