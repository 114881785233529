import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SuportOnlineModule } from './components/suport-online/suport-online.module';
import { FrequentQuestionsModule } from './components/frequent-questions/frequent-questions.module';
import { AlertDialogModule } from './utils/alert-dialog/alert-dialog.module';
import { DashboardLayoutModule } from './layout/dashboard-layout/dashboard-layout.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { PagesLayoutComponent } from './layout/pages-layout/pages-layout.component';
import { BearerInterceptor } from './interceptors/bearer.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { HttpLoadingInterceptor } from './interceptors/http-loading.interceptor';
import { DocumenteleMeleModule } from './components/documentele-mele/documentele-mele.module';

import { HomepageModule } from './homepage/homepage.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PreviewDocumentModalComponent } from './shared/preview-document-modal/preview-document-modal.component';
import { MaterialModule } from './material.module';

@NgModule({
    declarations: [AppComponent, PagesLayoutComponent, PreviewDocumentModalComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        JwtModule,
        SuportOnlineModule,
        FrequentQuestionsModule,
        AlertDialogModule,
        DashboardLayoutModule,
        ToastrModule.forRoot(),
        HomepageModule,
        PdfViewerModule,
        MaterialModule,
        DocumenteleMeleModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BearerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpLoadingInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
