import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/auth-module/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { helper } from 'src/environments/helper';

@Component({
    selector: 'app-noutati',
    templateUrl: './noutati.component.html',
    styleUrls: ['./noutati.component.scss']
})
export class NoutatiComponent implements OnInit {
    articleList: any = [];
    isLoading = false;
    disableLoadButton = false;
    helper = helper;
    currentPage = -1;
    maxPages: any;

    constructor(
        private authService: AuthService,
        private notificationService: NotificationService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.loadMoreArticles();
    }

    getArticles(page: number) {
        if (this.currentPage === 0) { this.isLoading = true };
        this.disableLoadButton = true;
        this.authService.getArticles(page)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: (res: any) => {
                    let response = (typeof res.status_code !== 'undefined' ? res : res.error);
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            this.articleList.push(...response.data.records);
                            this.maxPages = response.data.maxPages;
                            this.isLoading = false;
                            this.disableLoadButton = false;
                            return;
                        }
                    } else {
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        this.notificationService.warningSwal(environment.config.customNotifications.headers.error, errorMessage, environment.config.customNotifications.icons.error);
                    }
                },
                error: (res: any) => {
                    this.notificationService.handleHttpError(res.error);
                }
            });
    }

    loadMoreArticles() {
        this.currentPage++;
        this.getArticles(this.currentPage);
    }

    isMaxPage(): boolean {
        return this.currentPage >= this.maxPages;
    }

    formatDate(dateString: string): string {
        const [datePart] = dateString.split(' ');
        const [day, month, year] = datePart.split('/');

        const dateObj = new Date(Number(year), Number(month) - 1, Number(day));

        const monthName = [
            'IANUARIE', 'FEBRUARIE', 'MARTIE', 'APRILIE', 'MAI', 'IUNIE',
            'IULIE', 'AUGUST', 'SEPTEMBRIE', 'OCTOMBRIE', 'NOIEMBRIE', 'DECEMBRIE'
        ];

        return `${day} ${monthName[dateObj.getMonth()]} ${year}`;
    }

    navigateToArticle(articleId: string) {
        this.router.navigate([articleId], { relativeTo: this.route });
    }
}
