import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BeneficiarService {
    private baseUrl = environment.interop.authCore.basePath;
    private saveUrl = this.baseUrl + environment.interop.authCore.beneficiar.saveUrl;
    private updateUrl = this.baseUrl + environment.interop.authCore.beneficiar.updateUrl;
    private removeUrl = this.baseUrl + environment.interop.authCore.beneficiar.removeUrl;
    private listUrl = this.baseUrl + environment.interop.authCore.beneficiar.listUrl;
    private listValidatedUrl = this.baseUrl + environment.interop.authCore.beneficiar.listValidatedUrl;
    private findUrl = this.baseUrl + environment.interop.authCore.beneficiar.findUrl;

    constructor(private http: HttpClient) { }

    addBeneficiar(beneficiar: any): Observable<any> {
        return this.http.post(this.saveUrl, beneficiar);
    }

    updateBeneficiar(beneficiar: any): Observable<any> {
        return this.http.put(this.updateUrl, beneficiar);
    }

    removeBeneficiar(id: number): Observable<any> {
        return this.http.delete(`${this.removeUrl}/${id}`);
    }

    getBeneficiars(): Observable<any[]> {
        return this.http.get<any[]>(this.listUrl);
    }

    getValidatedBeneficiars(): Observable<any[]> {
        return this.http.get<any[]>(this.listValidatedUrl);
    }

    findBeneficiar(id: number) {
        return this.http.get(this.findUrl + id);
    }
}