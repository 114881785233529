import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { UtilsComponent } from './utils.component';
import { UtilsModalComponent } from './utils-modal/utils-modal.component';
import { AnnouncementsModule } from 'src/app/shared/announcements/announcements.module';

@NgModule({
  declarations: [UtilsComponent, UtilsModalComponent],
  imports: [CommonModule, MaterialModule, AnnouncementsModule],
})
export class UtilsModule {}
