<div class="modal_container">
    <div class="row justify-content-between m-0 p-3">
        <h2 class="w-auto">{{data.title}}</h2>
        <button type="button" class="btn_close w-auto" aria-label="Close" (click)="closeDialog()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div *ngIf="isLoading" style="margin-bottom: 20px; text-align: center;">
        Vă rugăm așteptați descărcarea documentului...
    </div>

    <div *ngIf="docLoadedWithSuccess" class="img-content">
        <div *ngIf="isPdf">
            <div class="pdf-container">
                <pdf-viewer [src]="pdfSource" [original-size]="false" [autoresize]="true" [show-borders]="true" (after-load-complete)="onLoad($event)">
                </pdf-viewer>
            </div>
        </div>

        <div *ngIf="isImage && !isLoading && imageSource" class="image-container p-5">
            <img [src]="imageSource" alt="Image" [style.transform]="rotateClass" class="fit-image rotate90">
            <button (click)="rotateImage()" class="btn_rotate">
                <mat-icon>rotate_right</mat-icon>
            </button>
        </div>
    </div>

    <div *ngIf="isImage && docLoadedWithSuccess && !isLoading && !imageSource" style="margin-bottom: 20px; text-align: center;">
        Formatul imaginii nu este suportat.
    </div>
</div>