import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth-module/services/auth.service';
import { StoreService } from '../services/store.service';

const helperJWT = new JwtHelperService();
const loginUrl = environment.config.loginUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {

    const token = this.authService.getAuthToken();

    if (token == null || token == '') {
      // redirect to login please
      this.router.navigateByUrl(loginUrl, { replaceUrl: true });
      return false
    } else {
      const isExpired = helperJWT.isTokenExpired(token)
      if (isExpired) {
        this.router.navigateByUrl(loginUrl, { replaceUrl: true });
        return false
      } else {
        // we are not expired.. please check the issuer
        const decodedToken = helperJWT.decodeToken(token)
        if (decodedToken) {
          const issuerUrl = new URL(decodedToken.iss);
          const hostname = issuerUrl.hostname;

          if (hostname !== environment.config.fromTokenValid) {
            this.router.navigateByUrl(loginUrl, { replaceUrl: true });
            return false
          } else {
            // we are fine here, please allow
            //this.authService.setAuthToken(token)
            return true
          }
        } else {
          this.router.navigateByUrl(loginUrl, { replaceUrl: true });
          return false
        }
      }
    }
  }
}