<div class="sidenav" [ngClass]="{'collapsed-sidebar': isCollapsed, 'sidebar': !isCollapsed}">
  <mat-drawer-container class="drawer-container" hasBackdrop="false">
    <mat-drawer class="drawer side-bar-class" [mode]="sidenavMode" opened>
    <div class="drawer__header">
        <div class="drawer__header--icon">
            <div class="hamburger-menu">
                <mat-icon class="chevron-button" [ngClass]="{'rotated': isCollapsed}"
                    (click)="toggleSidebar()">chevron_left</mat-icon>
            </div>
            <img routerLink="/" class="ps4-icon" src="assets/images/sect4-logo.svg" [alt]="appName" />
            <div class="drawer__header--text">
                <div routerLink="/" class="drawer__header--text--title">{{ appName }}</div>
                <div routerLink="/" class="drawer__header--text--title--minWidth">PS 4</div>
                <p class="drawer__header--text--title--urb"><i>{{ platformName }}</i></p>
                <div class="drawer__header--text--subtitle faded-text">
                    <!-- Portal -->
                </div>
                <div class="drawer__header--text--subtitle--minWidth faded-text">
                    eURB 4
                </div>
            </div>
            <div class="drawer__header--social-container">
                <a target="_blank" href="https://www.facebook.com/PrimariaSectorului4/?locale=ro_RO"
                    class="drawer__header--social">
                    <img src="assets/icons/facebook.svg" alt="facebok" />
                    <span class="drawer__header--social--text faded-text">Facebook</span>
                </a>
                <a target="_blank"
                    href="https://www.google.com/maps/place/Prim%C4%83ria+Sectorului+4/@44.4211052,26.086577,17z/data=!3m1!4b1!4m6!3m5!1s0x40b1ff734fba5a85:0xc1b4651cc5a5b6f9!8m2!3d44.4211014!4d26.0891519!16s%2Fg%2F1hc79bn9_?entry=ttu"
                    class="drawer__header--social">
                    <img src="assets/icons/pin-point.svg" alt="pin-point" />
                    <span class="drawer__header--social--text faded-text">
                        Locatie</span>
                </a>
            </div>
        </div>
    </div>
      <div class="drawer__buttons">
        <mat-button-toggle-group name="routing">
          <mat-button-toggle routerLink="/dashboard/home" value="home" *ngIf="!isProduction()">
            <img class="sidemenu-icons" src="assets/icons/home.svg" alt="Acasa" />
            <span>Acasă</span>
          </mat-button-toggle>
        <mat-button-toggle routerLink="/dashboard/solicitari/institutie" value="requests" *ngIf="!isProduction()">
            <img class="sidemenu-icons" src="assets/icons/docs.svg" alt="Adauga solicitare" />
            <span>Adaugă solicitare</span>
        </mat-button-toggle>
          <mat-button-toggle routerLink="/dashboard/solicitari/institutie/urbanism" value="requests" *ngIf="isProduction()">
            <img class="sidemenu-icons" src="assets/icons/docs.svg" alt="Adauga solicitare" />
            <span>Adaugă solicitare</span>
          </mat-button-toggle>
          <mat-button-toggle routerLink="/dashboard/solicitari" value="requests">
            <img class="sidemenu-icons" src="assets/icons/docs.svg" alt="Solicitarile mele" />
            <span>Solicitările mele</span>
          </mat-button-toggle>
          <mat-button-toggle routerLink="/dashboard/documentele-mele" value="myDocuments" *ngIf="!isProduction()">
            <img class="sidemenu-icons" src="assets/icons/documentele-mele.svg" alt="Documentele mele" />
            <span>Documentele mele</span>
          </mat-button-toggle>
          <mat-button-toggle routerLink="/dashboard/payments" value="payments" *ngIf="!isProduction()">
            <img class="sidemenu-icons" src="assets/icons/wallet.svg" alt="Plati Online" />
            <span>Plăți Online</span>
          </mat-button-toggle>
          <!-- <mat-button-toggle routerLink="/dashboard/utils" value="utils" *ngIf="!isProduction()">
            <img class="sidemenu-icons" src="assets/icons/icon-addcard.svg" alt="Utile" />
            <span>Utile</span>
          </mat-button-toggle> -->
          <mat-button-toggle routerLink="/dashboard/settings" value="settings">
            <img class="sidemenu-icons" src="assets/icons/gear.svg" alt="Setari" />
            <span>Setări</span>
          </mat-button-toggle>
        </mat-button-toggle-group>
        <div class="footer w-100">
            <div class="logout-button" (click)="logout()">
                <img src="assets/icons/logout.svg" alt="Deconectare" />
                <div class="logout-button--text">Deconectare</div>
            </div>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content class="drawer-content" [style.marginLeft.px]="sidenavMargin">
        <app-accessibility-toolbar *ngIf="portalLive == true"></app-accessibility-toolbar>
    
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>