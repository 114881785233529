<div class="section">
    <div class="section-content d-flex flex-lg-row flex-column justify-content-lg-between justify-content-center align-items-center">
        <span>Ai o urgență? Apelează Call Center</span>
        <button class="d-flex justify-content-center align-items-center mt-lg-0 mt-3" (click)="navigateToNewTab('tel:0219441', false)" type="button">
            <img src="assets/icons/phone.svg">(021) 9441
        </button>
    </div>
</div>

<div class="footer">
    <div class="footer-content row">
        <div class="footer-content-left d-flex flex-column col-xl-3 col-lg-4 align-items-lg-start align-items-center text-center text-lg-start">
            <div class="footer-content-left-header d-flex flex-lg-row flex-column align-items-center">
                <img src="assets/images/LogoPS4+MB.png" [alt]="appName"/>
                <span>{{ appName }}</span>
            </div>
            <div class="footer-content-left-details">
                <p class="footer-title">Adresa: 
                    <span class="footer-text footer-text-hover" (click)="navigateToNewTab('https://www.google.ro/maps/place/Bulevardul+George+Co%C8%99buc+6,+Bucure%C8%99ti/@44.4210487,26.0891953,21z/data=!4m5!3m4!1s0x40b1ff0d2e7a8df5:0x4c71788612e98c9b!8m2!3d44.4210595!4d26.0891953?hl=ro&entry=ttu')">
                        Bd. George Coșbuc, nr. 6-16, Sector 4, București, România
                    </span>
                </p>
                <p class="footer-title">Call center: 
                    <span class="footer-text footer-text-hover" (click)="navigateToNewTab('tel:0219441', false)">
                        021.9441
                    </span>
                </p>
                <p class="footer-title">Telefon: 
                    <span class="footer-text">
                        <span class="footer-text-hover" (click)="navigateToNewTab('tel:0213359230', false)">
                            021.335.92.30</span> / 
                        <span class="footer-text-hover" (click)="navigateToNewTab('tel:0213359236', false)">
                            021.335.92.36
                        </span>
                    </span>
                </p>
                <p class="footer-title">Fax: 
                    <span class="footer-text footer-text-hover" (click)="navigateToNewTab('tel:0213370790', false)">
                        021.337.07.90
                    </span>
                </p>
                <p class="footer-title">E-mail: 
                    <span class="footer-text footer-text-hover" (click)="navigateToNewTab('mailto:contact@ps4.ro', false)">
                        contact@ps4.ro
                    </span>
                </p>
            </div>
            <div>
                <img src="assets/images/RIA.png" />
            </div>
        </div>
        <div class="footer-content-right d-flex flex-column justify-content-lg-between col-xl-9 col-lg-8">
            <div class="footer-content-right-links d-flex flex-lg-row flex-column col-12 justify-content-lg-between justify-content-center align-items-lg-start align-items-center text-center text-lg-start">
                <div class="footer-content-right-links-first">
                    <h1>PRIMĂRIA</h1>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/wp-content/uploads/2022/04/Organigrama-PS4-30.12.2021.pdf')">Organigrama</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/wp-content/uploads/2022/04/ROF-PS4-30.12.2021.pdf')">Regulament de Funcționare</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/primaria/consilier-etic/')">Consilier etic</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/wp-content/uploads/2019/02/1204-1-3.pdf')">Regulament intern</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/monitorul-oficial-local/dispozitiile-autoritatii-executive/dispozitii/')">Dispoziții</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/primaria/rapoarte-oug-1092011/')">Rapoarte</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/primaria/declaratii-de-avere-si-interese-salariati/')">Declarații Avere și Interese Salariați</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/primaria/legislatia-in-baza-careia-functioneaza-institutia/')">Legislația în Baza Căreia Funcționează Instituția</span></p>
                </div>
                <div class="footer-content-right-links-first">
                    <h1>CONSILIUL LOCAL</h1>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/consiliul-local/comisii-de-specialitate/')">Comisii de Specialitate</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/consiliul-local/programarea-sedintelor/')">Programarea Ședințelor</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/monitorul-oficial-local/hotararile-autoritatii-deliberative/proiecte-de-hotarare/')">Proiecte de Hotărâre</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/monitorul-oficial-local/hotararile-autoritatii-deliberative/hotarari/')">Hotărâri</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/consiliul-local/legea-5502002/')">Legea 550/2002</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/consiliul-local/consilieri/')">Consilieri</span></p>
                    <br>
                    <br>
                    <h1>LIMITE DE HOTAR</h1>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/sectorul-4-in-imagini/limite-de-hotar/limita-sector-4-comuna-jilava/')">Limita Sector 4/ Comuna Jilava</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/sectorul-4-in-imagini/limite-de-hotar/limita-sector-4-popesti-leordeni/')">Limita Sector 4/ Popesti Leordeni</span></p>
                    <p>> <span (click)="navigateToNewTab('https://ps4.ro/sectorul-4-in-imagini/limite-de-hotar/limita-sector-4-sector-5/')">Limita Sector 4/ Sector 5</span></p>
                </div>
                <div class="footer-content-right-links-third d-flex flex-column align-items-center">
                    <div class="d-flex flex-row">
                        <img class="me-3" width="80" height="80" src="assets/images/logo112.png" (click)="navigateToNewTab('https://sts.ro/ro/despre-112/')"
                            alt="Serviciul de urgență 112" />
                        <img width="124" height="80" src="assets/images/InfoCons.png" (click)="navigateToNewTab('https://infocons.ro/')" 
                            alt="InfoCons" />
                    </div>
                    <img src="assets/icons/google-play.svg" (click)="navigateToNewTab('https://play.google.com/store/apps/details?id=ro.ps4.primariasector4app')" 
                        alt="Google Play" />
                    <img src="assets/icons/app-store.svg" (click)="navigateToNewTab('https://apps.apple.com/us/app/primaria-sectorului-4/id1484618433?ls=1')" 
                        alt="App Store" />
                    <img src="assets/icons/app-gallery.svg" (click)="navigateToNewTab('https://appgallery.huawei.com/app/C104319429')" 
                        alt="AppGallery" />
                </div>
            </div>
            <div class="footer-content-right-copyright col-12 d-flex gap-4 flex-column flex-lg-row justify-content-lg-between align-items-lg-start align-items-center">
                <p class="order-lg-1 order-4 mt-4 mt-lg-0">{{ copyrightText }}</p>
                <p class="links order-lg-2 order-1" (click)="openModal('termeni-si-conditii')">Termeni și Condiții</p>
                <p class="links order-lg-3 order-2" (click)="openModal('politica-de-confidentialitate')">Politica de Confidențialitate</p>
                <p class="links order-lg-4 order-3" (click)="openModal('politica-cookie')">Politică Cookie</p>
            </div>
        </div>
    </div>
</div>
