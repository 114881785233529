import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermeniConditiiModalComponent } from './termeni-conditii-modal.component';



@NgModule({
  declarations: [
    TermeniConditiiModalComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [TermeniConditiiModalComponent],
})
export class TermeniConditiiModalModule { }
