<app-accessibility-toolbar *ngIf="portalLive == true"></app-accessibility-toolbar>

<div class="faq-container">
    <div class="faq-welcome-card d-flex flex-column align-items-start align-self-stretch">
        <div class="faq-welcome-card-content d-flex justify-content-between align-items-center align-self-stretch">
            <span>Mai jos puteți găsi răspunsuri<br>
                la întrebările legate de Portalul nostru.</span>
        </div>
    </div>
    
    <div class="faq-content d-flex flex-column align-items-start align-self-stretch">    
        <mat-accordion *ngFor="let slug of slugs; let i = index">
            <p>{{ titles[i] }}</p>
            <mat-divider class="divider"></mat-divider>
            <ng-container *ngFor="let row of FAQList[slug]">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{row.name}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div [innerHTML]="row.observations"></div>
                </mat-expansion-panel>
                <mat-divider class="divider"></mat-divider>
            </ng-container>
		</mat-accordion>
    </div>
    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>