<div class="faq-container">
    <div class="navigation-bar">
        <div class="navigation">
            <button class="navigation__btn" mat-stroked-button color="primary" routerLink="/pages/login">
                <mat-icon>keyboard_arrow_left</mat-icon>
                INAPOI
                </button>
            <span routerLink="/pages/login" class="navigation__breadcrumbs">
                Autentificare
            </span>
            <span class="navigation__breadcrumbs faded_text">/</span>
            <span class="navigation__breadcrumbs faded_text">
                Vizualizare solicitare
            </span>
        </div>
    </div>



    <div class="faq-welcome-card d-flex flex-column align-items-start align-self-stretch">
        <div class="faq-welcome-card-content d-flex justify-content-between align-items-center align-self-stretch">
            <span>Mai jos puteți găsi o listă cu<br>
                ghidurile video ce conțin informații <span style="color:white">utile</span>.</span>
        </div>
    </div>
    <div style="background-color: white; border-radius: 10px; margin:10px 60px; padding:20px">
        <h4 style="font-size: 20px; margin-left: 20px;"><strong>Tutoriale</strong></h4>
        <div *ngFor="let tutorial of tutorials" class="tutorial-item">
            <div class="tutorial-card">
                <div class="icon-container">
                    <mat-icon class="video-icon">play_circle_filled</mat-icon>
                </div>
                <img class="video-thumbnail" src="assets/images/video-background.jpg" alt=""
                    (click)="openTutorialModal(tutorial.link)">
                <div class="tutorial-details">
                    <span class="tutorial-title">{{ tutorial.title }}</span>
                </div>
            </div>
        </div>
    </div>
</div>