<div class="frequent-card">
    <mat-accordion>
        <ng-container *ngFor="let row of FAQList">
            <mat-expansion-panel   class="suport-card__firstExpansion">
                <mat-expansion-panel-header>
                    
                    <mat-panel-title> {{row.name}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div [innerHTML]="row.observations"></div>
                
            </mat-expansion-panel>
            <mat-divider class="divider"></mat-divider>
        </ng-container>
        <div class="frequent-card__button">
            <button class="btn" (click)="closeDialog()">ÎNCHIDE</button>
        </div>
    </mat-accordion>
</div>
<div>
    <button type="button" class="close" aria-label="Close" (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>&nbsp;&nbsp;&nbsp;&nbsp;ÎNCHIDE
    </button>
</div>