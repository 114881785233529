import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { AccessibilityToolbarComponent } from './accessibility-toolbar.component';

@NgModule({
    declarations: [AccessibilityToolbarComponent],
    imports: [
        CommonModule,
        MaterialModule
    ],
    exports: [AccessibilityToolbarComponent],
})
export class AccessibilityToolbarModule { }
