import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-module/services/auth.service';

const TOKEN_KEY = environment.config.tokenKey;

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.get('Skip-Bearer-Interceptor')) {
            console.log('skipped interceptor for http request ', request.url);
            return next.handle(request);
        }

        const token = this.authService.getAuthToken();

        if (token) {
            const clonedRequest = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
            return next.handle(clonedRequest);
        } else {
            return next.handle(request);
        }
    }
}