import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumenteleMeleService } from './documentele-mele.service';
import { DataTablesResponse } from '../solicitari/data-table-response.interface';
import { AppFilesService } from 'src/app/services/app-files.service';
import { helper } from 'src/environments/helper';

@Component({
    selector: 'app-documentele-mele',
    templateUrl: './documentele-mele.component.html',
    styleUrls: ['./documentele-mele.component.scss']
})
export class DocumenteleMeleComponent {
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    isLoading = false;

    platformName = environment.config.platformName;

    helper = helper;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    dtLink = environment.interop.authCore.basePath + environment.interop.authCore.documenteleMele.getDocumenteleMeleDT;

    constructor(
        private http: HttpClient,
        private documenteleMeleService: DocumenteleMeleService,
        private appFilesService: AppFilesService,
    ) { }

    ngOnInit(): void {
        this.renderDT();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            order: [[0, 'desc']],
            serverSide: true,
            searching: false,
            responsive: true,
            lengthMenu: [10, 20, 50, 75, 100],
            pageLength: 20,

            ajax: (dataTablesParameters: any, callback: any) => {
                this.isLoading = true;

                this.http
                    .post<DataTablesResponse>(this.dtLink, dataTablesParameters)
                    .subscribe((resp: any) => {
                        this.isLoading = false;
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: resp.data.data,
                        });
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            columns: [
                {
                    title: 'ID Document',
                    data: 'id_document',
                },
                {
                    title: 'Tip Document',
                    data: 'document_type_name',
                    orderable: false,
                },
                {
                    title: 'Data încărcării',
                    data: 'uploaded_date',
                    render: (data: any) => {
                        return this.formatRomanianDate(data);
                    }
                },
                {
                    title: 'Documente',
                    data: 'files',
                    orderable: false,
                    className: 'all',
                    render: (row: any) => {
                        const icon = '<img src="assets/icons/icon-download.svg" alt="Icon download" />';
                        return row.map((file: any) => `<span style="cursor: pointer;" class="d-flex gap-2 align-items-center file-link" data-id-fisier="${file.id_fisier}">${icon} <span style="color: #0044CC99;">${file.orig_name}</span></span>`).join('<br>');
                    }
                },
                {
                    title: 'Data arhivării',
                    data: null,
                    render: (row: any) => {
                        if (row.archive_date !== null) {
                            return `<span>${this.formatRomanianDate(row.archive_date)}</span>`;
                        } else {
                            return `<span>N/A</span>`;
                        }
                    }
                },
            ],
            rowCallback: (row: Node, data: any[] | any) => {
                const fileLinks = (row as HTMLElement).querySelectorAll('.file-link');
                fileLinks.forEach(fileLink => {
                    fileLink.addEventListener('click', () => {
                        const { id_document } = data;
                        const id_fisier = parseInt(fileLink.getAttribute('data-id-fisier') || '0', 10);
                        this.downloadDocument(id_document, id_fisier);
                    });
                });

                return row;
            },
        };
    }

    rerenderDT(paginate = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(paginate);
        });
    }

    formatRomanianDate(dateTimeString: string): string {
        const [datePart, timePart] = dateTimeString.split(' ');
        const [year, month, day] = datePart.split('-');
        return `${day}.${month}.${year} ${timePart}`;
    }

    downloadDocument(idDocument: number, idFisier: number) {
        let fullPath = this.documenteleMeleService.fileDownloadUrl + idDocument + '/' + idFisier;
        this.isLoading = true;
        this.appFilesService.downloadFile(fullPath)
            .then(() => {
                this.isLoading = false;
            })
            .catch(err => {
                console.error('File download error: ', err);
                this.isLoading = false;
            })
    }
}
