<div class="utils-modal">
  <div class="utils-modal-title">
    <span>{{ data.title }}</span>
    <button
      class="utils-modal-button"
      mat-stroked-button
      color="primary"
      mat-dialog-close
    >
      <div class="button-content">
        <img src="assets/icons/icon-close.svg" alt="icon-close" />
        <span class="button-text">Închide</span>
      </div>
    </button>
  </div>
  <div class="utils-modal-content-container">
    <mat-divider></mat-divider>
    <div class="utils-modal-content" [innerHTML]="data.content">
      <!-- <p>
        Metodologia de gestionare a locurilor de parcare aflate pe domeniul
        public sau privat al Municipiului București de pe raza Sectorului 4,
        precum și de aplicare a unor sancțiuni contravenționale și/sau măsuri
        tehnico-administrative în ceea ce privește ocuparea fără drept a
        domeniului public sau privat al Municipiului București de pe raza
        Sectorului 4 cu autovehicule de orice tip
      </p>
      <br />
      <br />
      <br />
      <br />
      <p>TITLUL I</p>
      <p>Dispoziții Generale</p>
      <br />
      <br />
      <p>TITLUL II</p>
      <p>
        Atribuirea și utilizarea locurilor de parcare de reședință de pe raza
        Sectorului 4 al Municipiului București aflate în administrarea și
        exploatarea Direcției Mobilitate Urbană Sector 4
      </p>
      <br />
      <br />
      <p>TITLUL III</p>
      <p>
        Utilizarea parcărilor publice și atribuirea locurilor de parcare pe bază
        de abonament în parcările publice de pe raza Sectorului 4 al
        Municipiului București aflate în administrarea și exploatarea Direcției
        Mobilitate Urbană Sector 4
      </p>
      <br />
      <br />
      <p>TITLUL IV</p>
      <p>
        Regulamentul privind stabilirea și aplicarea măsurilor
        tehnico-administrative de blocare a roții autovehiculelor/vehiculelor
        care ocupă fără drept parcările și domeniul public sau privat al
        Municipiului București de pe raza Sectorului 4 al Municipiului București
      </p>
      <br />
      <br />
      <p>TITLUL V</p>
      <p>Contravenții și sancțiuni</p>
      <br />
      <br />
      <p>TITLUL VI</p>
      <p>Dispoziții finale</p> -->
    </div>
  </div>
</div>
