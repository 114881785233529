import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './auth-module/forgot-password/forgot-password.component';
import { LoginComponent } from './auth-module/login/login.component';
import { DashboardLayoutComponent } from './layout/dashboard-layout/dashboard-layout.component';
import { PagesLayoutComponent } from './layout/pages-layout/pages-layout.component';
import { RegisterComponent } from './auth-module/register/register.component';
import { HomeComponent } from './components/home/home.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { ConfirmAccountComponent } from './auth-module/confirm-account/confirm-account.component';
import { UtilsComponent } from './components/utils/utils.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { RegisterUniqueComponent } from './auth-module/register-unique/register-unique.component';
import { UserDataGuard } from './guards/user-data.guard';
import { HomepageComponent } from './homepage/homepage.component';
import { AcasaComponent } from './homepage/acasa/acasa.component';
import { NoutatiComponent } from './homepage/noutati/noutati.component';
import { FaqComponent } from './homepage/faq/faq.component';
import { UtileComponent } from './homepage/utile/utile.component';
import { ContactComponent } from './homepage/contact/contact.component';
import { ArticolComponent } from './homepage/articol/articol.component';
import { RegistreComponent } from './homepage/registre/registre.component';
import { CanActivateInDevGuard } from './guards/can-activate-in-dev.guard';
import { environment } from 'src/environments/environment';
import { VideoGuidesComponent } from './auth-module/login/watch-video-guide/video-guides.component';
import { ConfigExtensions } from './utils/config-extensions';
import { DocumenteleMeleComponent } from './components/documentele-mele/documentele-mele.component';

const devRoutes: Routes = [
    {
        path: '',
        component: HomepageComponent,
        children: [
            {
                path: '',
                component: AcasaComponent,
            },
            // {
            //     path: 'noutati',
            //     children: [
            //         {
            //             path: '',
            //             component: NoutatiComponent,
            //         },
            //         {
            //             path: ':id',
            //             component: ArticolComponent,
            //         },
            //     ],
            // },
            {
                path: 'faq',
                component: FaqComponent,
            },
            // {
            //     path: 'utile',
            //     component: UtileComponent,
            // },
            {
                path: 'ghiduri',
                component: VideoGuidesComponent,
            },
            // {
            //     path: 'registre',
            //     component: RegistreComponent,
            // },

            {
                path: 'contact',
                component: ContactComponent,
            },
            {
                path: 'pages/login',
                component: PagesLayoutComponent,
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('./auth-module/auth-module.module').then(
                                (m) => m.AuthModuleModule
                            ),
                        canActivate: [LoginGuard],
                    },
                ],
            },
            {
                path: 'public/solicitari/dgitl',
                loadChildren: () =>
                    import(
                        './components/solicitari/dgitl/add-solicitare/add-solicitare.module'
                    ).then((m) => m.AddSolicitareModule),
                canActivate: [CanActivateInDevGuard],
            },
            {
                path: 'public/solicitari/autorizari-comerciale',
                loadChildren: () =>
                    import(
                        './components/solicitari/autorizari-comerciale/solicitari-autorizari-comerciale.module'
                    ).then((m) => m.SolicitariAutorizariComercialeModule),
                canActivate: [CanActivateInDevGuard],
            },
            {
                path: 'public/programari-online',
                loadChildren: () =>
                    import(
                        './components/programari-online/programari-online.module'
                    ).then((m) => m.ProgramariOnlineModule),
                canActivate: [CanActivateInDevGuard],
            },
        ],
    },

    {
        path: 'dashboard',
        component: DashboardLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full',
            },
            {
                path: 'home',
                component: HomeComponent,
                canActivate: [CanActivateInDevGuard],
            },
            {
                path: 'solicitari',
                canActivate: [UserDataGuard],
                loadChildren: () =>
                    import('./components/solicitari/solicitari.module').then(
                        (m) => m.SolicitariModule
                    ),
            },
            {
                path: 'documentele-mele',
                component: DocumenteleMeleComponent,
            },
            {
                path: 'payments',
                loadChildren: () =>
                    import('./payments/payments.module').then((m) => m.PaymentsModule),
            },
            {
                path: 'utils',
                component: UtilsComponent,
            },
            {
                path: 'settings',
                component: SettingsComponent,
            },
            {
                path: 'notifications',
                component: NotificationsComponent,
            },
        ],
    },
    {
        path: '**',
        redirectTo: '',
    },
];

const prodRoutes: Routes = [
    {
        path: '',
        component: HomepageComponent,
        children: [
            // {
            // 	path: '',
            // 	component: AcasaComponent,
            // },
            // {
            // 	path: 'faq',
            // 	component: FaqComponent,
            // },
            // {
            // 	path: 'registre',
            // 	component: RegistreComponent,
            // },

            // {
            // 	path: 'contact',
            // 	component: ContactComponent,
            // },
            {
                path: 'pages/login',
                component: PagesLayoutComponent,
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('./auth-module/auth-module.module').then(
                                (m) => m.AuthModuleModule
                            ),
                        canActivate: [LoginGuard],
                    }
                ],
            },
            {
                path: 'ghiduri',
                component: VideoGuidesComponent,
            },
            {
                path: 'dashboard',
                component: DashboardLayoutComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        redirectTo: 'home',
                        pathMatch: 'full',
                    },
                    {
                        path: 'home',
                        component: HomeComponent,
                        canActivate: [CanActivateInDevGuard],
                    },
                    {
                        path: 'solicitari',
                        canActivate: [UserDataGuard],
                        loadChildren: () =>
                            import('./components/solicitari/solicitari.module').then(
                                (m) => m.SolicitariModule
                            ),
                    },
                    // { // dezactivate temporar
                    //     path: 'documentele-mele',
                    //     component: DocumenteleMeleComponent,
                    // },
                    // { // dezactivate temporar
                    // 	path: 'payments',
                    // 	loadChildren: () =>
                    // 		import('./payments/payments.module').then((m) => m.PaymentsModule),
                    // },
                    // { // dezactivate temporar
                    // 	path: 'utils',
                    // 	component: UtilsComponent,
                    // },
                    {
                        path: 'settings',
                        component: SettingsComponent,
                    },
                    // { // dezactivate temporar
                    // 	path: 'notifications',
                    // 	component: NotificationsComponent,
                    // },
                ],
            },
            {
                path: '**',
                redirectTo: 'pages/login',
            },
        ]
    }
];

const routes = ConfigExtensions.isProductionWithLimitedFeatures() ? prodRoutes : devRoutes;

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            useHash: true,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
