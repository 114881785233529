import { Injectable } from '@angular/core';
import { ScreenSize } from '../enums/screen-size.enum';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private screenSize = ScreenSize.SM;
  private heightSize = ScreenSize.HBP1;

  constructor() {
    window.removeEventListener('resize', () => this.setScreenSize());
    window.addEventListener('resize', () => this.setScreenSize());
    this.setScreenSize();
   }

   private setScreenSize() {
    innerWidth < ScreenSize.BP && (this.screenSize = ScreenSize.BP)
    innerWidth >= ScreenSize.BP && (this.screenSize = ScreenSize.XS);
    innerWidth >= ScreenSize.SM && (this.screenSize = ScreenSize.SM);
    innerWidth >= ScreenSize.MD && (this.screenSize = ScreenSize.MD);
    innerWidth >= ScreenSize.LG && (this.screenSize = ScreenSize.LG);
    innerWidth >= ScreenSize.XL && (this.screenSize = ScreenSize.XL);

    innerHeight >= ScreenSize.HBP1 && (this.screenSize = ScreenSize.HBP1);
   }

   getScreenSize() {
    if (this.isBP()) return 'bp';
    if (this.isXS()) return 'xs';
    if (this.isSM()) return 'sm';
    if (this.isMD()) return 'md';
    if (this.isLG()) return 'lg';
    if (this.isXL()) return 'xl';
    if (this.isHBP1()) return 'hbp1';
    return 'xs';
   }

   isBP() {
    return this.screenSize == ScreenSize.BP
   }

   isXS() {
    return this.screenSize == ScreenSize.XS;
   }

   isSM() {
    return this.screenSize == ScreenSize.SM;
   }

   isMD() {
    return this.screenSize == ScreenSize.MD;
   }

   isLG() {
    return this.screenSize == ScreenSize.LG;
   }

   isXL() {
    return this.screenSize == ScreenSize.XL;
   }

   isHBP1() {
    return this.screenSize == ScreenSize.HBP1;
   }
}
