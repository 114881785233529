import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ICountryOption, countriesList } from '../../shared/countries-list';
import { AuthService } from 'src/app/auth-module/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { finalize } from 'rxjs';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    appName = environment.config.appName;
    public portalLive = environment.portalLive;
    
    formContact: FormGroup = new FormGroup({
        nume: new FormControl(''),
        prenume: new FormControl(''),
        email: new FormControl(''),
        telefon: new FormControl(''),
        adresa: new FormControl(''),
        subiect: new FormControl(''),
        mesaj: new FormControl(''),
        files: new FormControl(''),
        prelucrareDate: new FormControl('')
    })

    isLoading = false;

    optionRom: ICountryOption = {
        name: 'Romania',
        flag: '🇷🇴',
        code: 'RO',
        dial_code: '+40',
    };

    currentOption = this.optionRom;
    options: ICountryOption[] = countriesList;

    changeCountry(option: ICountryOption) {
        this.currentOption = option;
    }

    uploading: boolean[] = [];

    files: any = [];

    env: any = environment

    // general error
    errorTitle: string = environment.config.customNotifications.headers.error
    errorIcon: string = environment.config.customNotifications.icons.error
    errorType: string = environment.config.customNotifications.icons.error
    // general success alert
    successTitle: string = environment.config.customNotifications.headers.successForm
    successIcon: string = environment.config.customNotifications.icons.success
    successType: string = environment.config.customNotifications.icons.success

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private notificationService: NotificationService,
        public toastr: ToastrService,
    ) { }

    ngOnInit(): void {
        this.initContactForm();
    }

    initContactForm() {
        this.formContact = this.fb.group({
            nume: ['', [Validators.required]],
            prenume: ['', [Validators.required]],
            email: ['', [
                Validators.required,
                Validators.pattern(environment.config.emailRegex),
                Validators.maxLength(environment.config.validatorsAccrossApp.emailMaxLength),
                Validators.email
            ]],
            telefon: ['', [Validators.required]],
            adresa: ['', [Validators.required]],
            subiect: ['', [Validators.required]],
            mesaj: ['', [Validators.required]],
            files: [''],
            prelucrareDate: [false, [Validators.requiredTrue]]
        })
    }

    resetControlsState(formGroup: FormGroup) {
        formGroup.reset();
        formGroup.setErrors({ 'invalid': true });
        Object.keys(formGroup.controls).forEach(controlName => {
            const control = formGroup.get(controlName);
            control?.setErrors(null);
            control?.markAsPristine();
            control?.markAsUntouched();
        });
    }

    submitForm() {
        this.isLoading = true;
        let phone: any;

        const teletelefonInput = this.currentOption.dial_code + this.formContact.value.telefon;
        const telefon = phoneNumberUtil.parseAndKeepRawInput(teletelefonInput)
        let regionCodeLower = '';
        let nationalNumberString = '';
        if (telefon) {
            const regionCode = phoneNumberUtil.getRegionCodeForNumber(telefon);
            if (regionCode) {
                regionCodeLower = (regionCode as string).toLowerCase();
            }

            const nationalNumber = telefon.getNationalNumber();

            if (nationalNumber) {
                nationalNumberString = nationalNumber.toString();
            }
        }

        phone = {
            dialCode: '',
            internationalNumber: teletelefonInput,
            isoCode: regionCodeLower,
            nationalNumber: nationalNumberString
        }

        phone.dialCode = teletelefonInput.replace(phone.nationalNumber, '');
        phone.nationalNumber = (phone.isoCode == 'ro' ? '0' + phone.nationalNumber : phone.nationalNumber);

        const filesToSend = this.formContact.get('files')?.value || [];

        const formData = new FormData();
        Object.keys(this.formContact.value).forEach(key => {
            if (key != 'files') {
                if (key === 'telefon') {
                    formData.append(key, phone.internationalNumber);
                } else if (key === 'prelucrareDate') {
                    formData.append(key, this.formContact.get(key)?.value ? '1' : '0');
                } else {
                    formData.append(key, this.formContact.value[key]);
                }
            }
        })

        filesToSend.forEach((file: any) => {
            formData.append('files[]', file);
        });

        this.authService.sendFormContact(formData)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: (res: any) => {
                    let response = (typeof res.status_code !== 'undefined' ? res : res.error);
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            this.isLoading = false;
                            this.notificationService.warningSwal(this.successTitle, 'Formularul dvs a fost trimis cu succes', this.successIcon);
                            this.resetControlsState(this.formContact);
                        }
                    } else {
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        this.isLoading = false;
                        this.notificationService.warningSwal(this.errorTitle, errorMessage, this.errorIcon);
                    }
                },
                error: (res: any) => {
                    this.isLoading = false;
                    this.onHttpError(res.error);
                    return false;
                }
            });
    }

    addFile(event: any) {
        const inputElement = event.target as HTMLInputElement;
        if (!inputElement.files) {
            return;
        }

        const files = inputElement.files;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            const currentFiles = this.formContact.get('files')?.value || [];
            currentFiles.push(file);
            this.formContact.get('files')?.setValue(currentFiles);

            this.uploading.push(true);

            setTimeout(() => {
                this.uploading.fill(false);

                this.openToast({
                    title: this.successTitle,
                    message: 'Fișierul dvs. a fost adăugat cu succes.',
                    type: this.successIcon
                });
            }, 1000);
        }
    }

    removeFile(index: number) {
        const currentFiles = this.formContact.get('files')?.value || [];
        currentFiles.splice(index, 1);
        this.uploading.splice(index, 1);
        this.formContact.get('files')?.setValue(currentFiles);
    }

    openToast(object: any) {
        const { type, message, title } = object;

        let options = this.toastr.toastrConfig;
        options.progressBar = true;
        options.preventDuplicates = true;
        options.closeButton = true;

        const inserted = this.toastr.show(message, title, options, options.iconClasses[type]);
    }

    onHttpError(res: any, showErrorsInToast: boolean = false) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                if (showErrorsInToast) {
                    this.openToast({ title: this.errorTitle, message: msg, type: this.errorType });
                }
                else {
                    this.notificationService.warningSwal(this.errorTitle, msg, this.errorIcon);
                }
            });
        }
        else {
            let errorMessage = environment.config.customNotifications.generalMessages.error;
            this.notificationService.warningSwal(this.errorTitle, errorMessage, this.errorIcon);
        }
    }
}
