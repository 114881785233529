import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-utils-modal',
  templateUrl: './utils-modal.component.html',
  styleUrls: ['./utils-modal.component.scss']
})
export class UtilsModalComponent {
  suportDialogRef: MatDialogRef<any> | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, content: string}) { }
}
