import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Address, User } from '../models';
import { combineLatest, map, Observable, of, ReplaySubject } from 'rxjs';
import { AuthService } from '../auth-module/services/auth.service';
import { BeneficiarService } from './beneficiar.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userLoadedSubject = new ReplaySubject<boolean>();
  private addressesLoadedSubject = new ReplaySubject<boolean>();
  private beneficiariLoadedSubject = new ReplaySubject<boolean>();


  userAddresses: { domiciliu: Address[], sediu: Address[] } = {
    domiciliu: [],
    sediu: [],
  };

  user: null | User = null;
  beneficiari = [];

  private authPath = environment.interop.authCore.basePath;
  private findDetailsUrl = environment.interop.authCore.user.findDetails
  private updatePasswordAccountUrl = environment.interop.authCore.user.updatePasswordAccount;
  private updateInfoAccountUrl = environment.interop.authCore.user.updateInfoAccount;
  private addressSaveUrl = environment.interop.authCore.user.addressSave;
  private addressUpdateUrl = environment.interop.authCore.user.addressUpdate;
  private addressListUrl = environment.interop.authCore.user.addressList;
  private addressDeleteUrl = environment.interop.authCore.user.addressDelete;

  constructor(private http: HttpClient, private authService: AuthService, private beneficiarService: BeneficiarService) { }

  findDetails() {
    return this.http.get<{ data: any }>(this.authPath + this.findDetailsUrl);
  }

  // settings for account
  updatePasswordAccount(data: any) {
    return this.http.post<{ data: any }>(this.authPath + this.updatePasswordAccountUrl, data);
  }

  updateInfoAccount(data: any) {
    return this.http.post<{ data: any }>(this.authPath + this.updateInfoAccountUrl, data);
  }

  // settings for address
  addressSave(data: any) {
    return this.http.post<{ data: any }>(this.authPath + this.addressSaveUrl, data);
  }

  addressUpdate(data: any) {
    return this.http.post<{ data: any }>(this.authPath + this.addressUpdateUrl, data);
  }

  addressList() {
    return this.http.get<{ data: any }>(this.authPath + this.addressListUrl);
  }

  addressDelete(id: number) {
    return this.http.delete<{ data: any }>(this.authPath + this.addressDeleteUrl + id);
  }

  isDataLoadedObservable(): Observable<boolean> {
    this.user = this.getAccountDetails();
    this.listAddress();
    this.fetchBeneficiari();

    return combineLatest([this.userLoadedSubject, this.addressesLoadedSubject, this.beneficiariLoadedSubject]).pipe(
      map(([userLoaded, addressesLoaded, beneficiariLoaded]: [boolean, boolean, boolean]) => {
        console.log(`UserService - user loaded ${userLoaded}, address loaded ${addressesLoaded}, beneficiari loaded ${beneficiariLoaded}`);
        return userLoaded && addressesLoaded && beneficiariLoaded
      })
    );
  }


  isUserPersoanaFizica() {
    return this.user ?.type === 1;
  }

  isUserPersoanaJuridica() {
    return this.user ?.type === 2;
  }

  generateAddress() {
    const addresses = this.user ?.type === 1 ? this.userAddresses.domiciliu : this.userAddresses.sediu;

    if (addresses && addresses.length > 0) {
      const preferredAddress = addresses.find(
        (address: any) =>
          address.default_domiciliu !== null ||
          address.default_sediu_social !== null
      );
      const address = preferredAddress || addresses[0];

      return this.generateAddressString(address);
    } else {
      // No addresses are present
      return 'N/A';
    }
  }

  generateAddressString(address: Address): string {
    let addressParts = [];

    if (address.strada) {
      addressParts.push(address.strada);
    }
    if (address.numar) {
      addressParts.push(`nr. ${address.numar}`);
    }
    if (address.etaj) {
      addressParts.push(`et.${address.etaj}`);
    }
    if (address.apartament) {
      addressParts.push(`apartament ${address.apartament}`);
    }
    if (address.oras) {
      addressParts.push(address.oras);
    }
    if (address.judet_name) {
      addressParts.push(address.judet_name);
    }

    return addressParts.join(', ');
  }

  private getAccountDetails(): User {
    const storedUserString = localStorage.getItem(environment.config.userKey);

    if (storedUserString) {
      // Parse the string to convert it into an object
      const user = JSON.parse(storedUserString);
      this.userLoadedSubject.next(true);
      return user;
    } else {
      throw "User not found in storage";
    }
  }

  private listAddress() {
    this.addressList().subscribe({
      next: (res: any) => {
        this.userAddresses = res.data;

        this.addressesLoadedSubject.next(true);
      },
      error: (err: any) => {
        throw "Failed to fetch user addresses";
      },
    });
  }

  private fetchBeneficiari(): void {
    this.beneficiarService.getBeneficiars()
      .subscribe({
        next: (res: any) => {
          this.beneficiari = res.data;
          this.beneficiariLoadedSubject.next(true);
        },
        error: (err: any) => {
          console.log(err);
        }
      });
  }
}
