import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-accessibility-toolbar',
    templateUrl: './accessibility-toolbar.component.html',
    styleUrls: ['./accessibility-toolbar.component.scss']
})
export class AccessibilityToolbarComponent implements OnInit {
    currentFontSize: number = 130;
    maxFontSize: number = 200;
    minFontSize: number = 130;
    step: number = 10;
    isToolbarOpen = false;

    ngOnInit() {
        this.initializeState();
    }

    toggleToolbar() {
        this.isToolbarOpen = !this.isToolbarOpen;

        if (this.isToolbarOpen)
            this.initializeState();

    }

    get accessibilityCache() {
        const pojoA11y = localStorage.getItem('pojo-a11y');
        const accessibilityState = pojoA11y ? JSON.parse(pojoA11y) : null;
        return accessibilityState;
    }

    initializeState() {
        if (this.accessibilityCache) {
            for (let action in this.accessibilityCache.actions) {
                if (this.accessibilityCache.actions[action]) {
                    if (action === 'resize-plus') {
                        // init accessibility font
                        const cachedFontSize = this.accessibilityCache.variables['currentFontSize'];
                        this.currentFontSize = cachedFontSize
                        document.body.classList.add(`pojo-a11y-resize-font-${cachedFontSize}`);
                    } else {
                        document.body.classList.add(`pojo-a11y-${action}`);
                    }
                    this.updateActiveClass(action);
                }
            }
        }
    }

    updateLocalStorage(action: string, value: boolean) {
        let accessibilityState = this.accessibilityCache || {
            actions: {
                'resize-plus': false,
                'resize-minus': false,
                'links-underline': false,
                'grayscale': false,
                'high-contrast': false,
                'negative-contrast': false,
                'light-background': false,
                'readable-font': false,
            },
            variables: {
                currentFontSize: this.minFontSize,
                //expires: Date.now() + 365 * 24 * 60 * 60 * 1000 // what to do with it ???
            }
        };
        accessibilityState.actions[action] = value;
        accessibilityState.variables.currentFontSize = this.currentFontSize;
        localStorage.setItem('pojo-a11y', JSON.stringify(accessibilityState));
    }

    updateActiveClass(action: string) {
        const link = document.querySelector(`a[data-action="${action}"]`);
        console.log(action, link, this.accessibilityCache)
        if (this.accessibilityCache && this.accessibilityCache.actions[action]) {
            link?.classList.add('active');
        } else {
            link?.classList.remove('active');
        }
    }

    toggleLinksUnderline() {
        const body = document.body;
        body.classList.toggle('pojo-a11y-links-underline');

        this.updateLocalStorage('links-underline', body.classList.contains('pojo-a11y-links-underline'));
        this.updateActiveClass('links-underline');
    }

    toggleReadableFont() {
        const body = document.body;
        body.classList.toggle('pojo-a11y-readable-font');

        this.updateLocalStorage('readable-font', body.classList.contains('pojo-a11y-readable-font'));
        this.updateActiveClass('readable-font');
    }

    toggleSchemeAction(dataAction: string) {
        const body = document.body;
        const classList = body.classList;

        const schemeActionGroupElements = document.querySelectorAll("[data-action-group='schema']");
        schemeActionGroupElements.forEach(item => {
            const action = item.getAttribute('data-action') || '';
            if (action !== dataAction) {
                // Remove the scheme class from the body
                body.classList.remove(`pojo-a11y-${action}`);
                this.updateLocalStorage(action, false);
                this.updateActiveClass(action);
            }
        });

        const toggleClassName = `pojo-a11y-${dataAction}`
        classList.toggle(toggleClassName);
        this.updateLocalStorage(dataAction, body.classList.contains(toggleClassName));
        this.updateActiveClass(dataAction);
    }

    increaseFontSize() {
        if (this.currentFontSize < this.maxFontSize) {
            this.currentFontSize += this.step;
            this.updateBodyClass();
        }
    }

    decreaseFontSize() {
        if (this.currentFontSize > this.minFontSize) {
            this.currentFontSize -= this.step;
            this.updateBodyClass();
        }
    }

    updateBodyClass() {
        const body = document.body;
        const classList = body.classList;

        // Remove any existing font size class
        classList.forEach(className => {
            if (className.startsWith('pojo-a11y-resize-font-')) {
                classList.remove(className);
            }
        });

        const fontClass = `pojo-a11y-resize-font-${this.currentFontSize}`;

        // Add the new font size class
        if (this.currentFontSize !== this.minFontSize) {
            classList.add(fontClass);
        }

        this.updateLocalStorage('resize-plus', classList.contains(fontClass));
        this.updateActiveClass('resize-plus');
    }

    resetAccessibilityTools() {
        const body = document.body;
        const classList = Array.from(body.classList);

        // remove any existing font size class from body
        classList.forEach(className => {
            if (className.startsWith('pojo-a11y-')) {
                body.classList.remove(className);
            }
        });

        // remove any existing active class from a tags from the toolbar
        const links = document.querySelectorAll('#pojo-a11y-toolbar a');
        links.forEach(link => {
            link.classList.remove('active');
        });

        // reset font
        this.currentFontSize = 130;

        localStorage.removeItem('pojo-a11y');
    }
}
