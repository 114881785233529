import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../auth-module/services/auth.service';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { UtilsModalComponent } from './utils-modal/utils-modal.component';

@Component({
  selector: 'app-utils',
  templateUrl: './utils.component.html',
  styleUrls: ['./utils.component.scss'],
})
export class UtilsComponent implements OnInit {
  utilsList: any = [];
  isLoading = false;
  // pageSlug: string = 'UTILE_CONTENT_PAGE';
  pageSlugContent: string = 'UTILE_CONTENT_PAGE'; // To test local/development
  // pageSlug: string = 'UTILE_ANNOUNCEMENTS_PAGE';
  pageSlugAnnouncements: string = 'section_1'; // To test local/development

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.getContent();
  }

  getContent() {
    var self = this;
    this.isLoading = true;
    this.authService
      // To be changed to getContent()
      .getFAQ(this.pageSlugContent)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res: any) => {
          console.log(res, 'utils component');
          let response =
            typeof res.status_code !== 'undefined' ? res : res.error;
          if (typeof response.status_code !== 'undefined') {
            if (
              response.status_code == 200 &&
              typeof response.data !== 'undefined'
            ) {
              self.utilsList = response.data;
              self.isLoading = false;
              return false;
            } else {
              return false;
            }
          } else {
            return false;
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  openUtilsModal(data: any) {
    const suportDialogRef = this.dialog.open(UtilsModalComponent, {
      maxHeight: '95vh',
      maxWidth: '90vw',
      data: {
        title: data.name,
        content: data.observations,
      },
    });
    Object.assign(suportDialogRef.componentInstance, { suportDialogRef });
  }
}
