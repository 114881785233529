<div class="announcements">
    <div class="item" *ngFor="let announcement of announcementsList">
        <mat-icon>info</mat-icon>
        <div>
            <h6 class="p-2">{{announcement.title}}</h6>
            <div class="item-message" [innerHTML]="announcement.content"></div>
        </div>
    </div>
    
    <div class="d-flex align-items-center justify-content-center" *ngIf="isLoading">
            <mat-spinner diameter="32" class="me-2" color="primary"></mat-spinner>
            <h3 class="m-0">Se încarcă anunțurile ...</h3>
    </div>
</div>
