import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth-module/services/auth.service';
import { MatDrawerMode } from '@angular/material/sidenav';
import { environment } from 'src/environments/environment';
import { ConfigExtensions } from 'src/app/utils/config-extensions';

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  appName: string = environment.config.appName;
  platformName: string = environment.config.platformName;

  portalLive: boolean = environment.portalLive;

  isCollapsed = false;
  sidenavMode: MatDrawerMode = 'side';
  sidenavMargin: number = 280;
  public isMenuOpen: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.onResize();
  }

  isProduction() {
      return ConfigExtensions.isProductionWithLimitedFeatures();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  toggleSidebar() {
    this.isCollapsed = !this.isCollapsed;
    this.sidenavMargin = this.isCollapsed ? 85 : 280;
  }

  public onSidenavClick($event: any): void {
    this.isMenuOpen = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isCollapsed = window.innerWidth < 960;
    this.sidenavMode = window.innerWidth <= 370 ? 'over' : 'side';

    if (this.sidenavMode === 'side' && this.isCollapsed === false) {
      this.sidenavMargin = 280;
    } else if (this.sidenavMode === 'side' && this.isCollapsed === true) {
      this.sidenavMargin = 85;
    } else if (this.sidenavMode === 'over') {
      this.sidenavMargin = 0;
    }
  }



}
