<div class="utile-container">
    <div class="utile-welcome-card d-flex flex-column align-items-start align-self-stretch">
        <div class="utile-welcome-card-content d-flex justify-content-between align-items-center align-self-stretch">
            <span>Mai jos puteți găsi o listă cu<br>
                documente și informații utile.</span>
        </div>
    </div>
    
    <div class="utile-content d-flex flex-column align-items-start align-self-stretch">    
        <div class="docs-list">
            <div class="items" *ngFor="let item of dropdownItems; let i = index">
                <span>{{ i + 1 }}. {{ item.name }}</span>
                <mat-icon class="icon">keyboard_arrow_right</mat-icon>
            </div>
        </div>
    </div>

    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>