import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { AuthService } from 'src/app/auth-module/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { helper } from 'src/environments/helper';

@Component({
    selector: 'app-articol',
    templateUrl: './articol.component.html',
    styleUrls: ['./articol.component.scss']
})
export class ArticolComponent {
    articleDetails: any;
    isLoading = false;
    helper = helper;

    constructor(
        private authService: AuthService,
        private notificationService: NotificationService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.getArticleDetails();
    }

    getArticleDetails() {
        const articleId = this.route.snapshot.params['id'];

        this.isLoading = true;
        this.authService.getArticleDetails(articleId)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe({
                next: (res: any) => {
                    let response = (typeof res.status_code !== 'undefined' ? res : res.error);
                    if (typeof response.status_code !== 'undefined') {
                        if (response.status_code == 200 && typeof response.data !== 'undefined') {
                            this.articleDetails = response.data;
                            this.isLoading = false;
                            return;
                        }
                    } else {
                        let errorMessage = environment.config.customNotifications.generalMessages.error;
                        this.notificationService.warningSwal(environment.config.customNotifications.headers.error, errorMessage, environment.config.customNotifications.icons.error);
                    }
                },
                error: (res: any) => {
                    this.notificationService.handleHttpError(res.error);
                }
            })
    }

    formatDate(dateString: string): string {
        const [datePart] = dateString.split(' ');
        const [day, month, year] = datePart.split('/');

        const dateObj = new Date(Number(year), Number(month) - 1, Number(day));

        const monthName = [
            'IANUARIE', 'FEBRUARIE', 'MARTIE', 'APRILIE', 'MAI', 'IUNIE',
            'IULIE', 'AUGUST', 'SEPTEMBRIE', 'OCTOMBRIE', 'NOIEMBRIE', 'DECEMBRIE'
        ];

        return `${day} ${monthName[dateObj.getMonth()]} ${year}`;
    }
}
