import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { HomepageComponent } from './homepage.component';
import { NoutatiComponent } from './noutati/noutati.component';
import { FaqComponent } from './faq/faq.component';
import { UtileComponent } from './utile/utile.component';
import { ContactComponent } from './contact/contact.component';
import { AcasaComponent } from './acasa/acasa.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArticolComponent } from './articol/articol.component';
import { DialogComponent } from './dialog/dialog.component';
import { RegistreComponent } from './registre/registre.component';
import { AccessibilityToolbarModule } from '../components/accessibility-toolbar/accessibility-toolbar.module';


@NgModule({
    declarations: [HomepageComponent, AcasaComponent, NoutatiComponent, FaqComponent, UtileComponent, ContactComponent, FooterComponent, ArticolComponent, DialogComponent, RegistreComponent],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        AccessibilityToolbarModule
    ],
})
export class HomepageModule { }
