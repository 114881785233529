import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth-module/services/auth.service';
import { ConfigExtensions } from '../utils/config-extensions';

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
    appName: string = environment.config.appName;
    public production : boolean = environment.production;
    public portalLive : boolean = environment.portalLive;
    activeRoute!: string;
    username: string = '';
    usernameLetters: string = '';
    sidenavOpen = false;

    @ViewChildren('sidenavContainer, menuButton, closeButton') sidenavElements!: QueryList<ElementRef>;

    constructor(
        private router: Router,
        public authService: AuthService,
        private eRef: ElementRef
    ) {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {

                this.activeRoute = this.router.url;
            });
    }

    ngOnInit(): void {
        this.getUserDetails();
    }

    @HostListener('document:click', ['$event'])
    clickout(event: Event): void {
        // Check if the clicked element is one of the specified elements
        const clickedElement = event.target as HTMLElement;
        if (!this.sidenavElements.some(elementRef => elementRef.nativeElement.contains(clickedElement))) {
            // If none of the elements were clicked, toggle the sidenav
            this.sidenavOpen = false;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
        const windowWidth = window.innerWidth;

        if (windowWidth > 1200) {
            this.sidenavOpen = false;
        }
    }

    getUserDetails() {
        const user = localStorage.getItem(environment.config.userKey);
        if (!user) return;

        let jsonUser = JSON.parse(user);
        if (jsonUser && jsonUser.prenume && jsonUser.nume) {
            this.username = jsonUser.prenume + ' ' + jsonUser.nume;
        } else {
            this.username = 'Not logged in';
        }

        this.extractLetters(this.username);
    }

    extractLetters(username: string) {
        const firstLetters = username.split(' ').map((word) => word.charAt(0));
        this.usernameLetters = firstLetters.slice(0, 2).join('');
    }

    toggleSidenav() {
        this.sidenavOpen = !this.sidenavOpen;
    }

    isProduction() {
        return ConfigExtensions.isProductionWithLimitedFeatures();
    }

}
