import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigExtensions } from '../utils/config-extensions';

@Injectable({
    providedIn: 'root',
})
export class CanActivateInDevGuard implements CanActivate {
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (ConfigExtensions.isProductionWithLimitedFeatures()) {
            // Production environment, block access to dev routes
            console.error('Access to some routes is not allowed in production with limited features.');
            return false;
        }

        // Development environment, allow access to dev routes
        return true;
    }
}