import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SolicitareComponent } from '../solicitare/solicitare.component';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { DialogService } from 'src/app/utils/services/dialog.service';
import { UserService } from 'src/app/services/user.service';
import { StoreService } from 'src/app/services/store.service';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { NotificationService } from '../../services/notification.service';
import { environment } from 'src/environments/environment';
import { ICountryOption, countriesList } from '../../shared/countries-list';
import { SuportOnlineComponent } from '../suport-online/suport-online.component';
import { Router } from '@angular/router';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
import { EventsService } from '../../services/events.service';
import { USER_UPDATED } from '../../shared/events';
import { finalize, map } from 'rxjs';
import { TermeniConditiiModalComponent } from 'src/app/shared/termeni-conditii-modal/termeni-conditii-modal.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MyErrorStateMatcher } from 'src/app/auth-module/register-unique/register-unique.component';
import { notDefaultValidator } from 'src/app/shared/validators/not-default';
import { Generic } from 'src/app/interfaces/generic.interface';
import { CommonService } from 'src/app/services/common.service';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

interface Address {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
    platformName = environment.config.platformName;

    constructor(
        private dialog: MatDialog,
        private formsBuilder: FormBuilder,
        private dialogService: DialogService,
        private userService: UserService,
        private storeService: StoreService,
        private toastr: ToastrService,
        private notificationService: NotificationService,
        private router: Router,
        private eventService: EventsService,
        private breakpointObserver: BreakpointObserver,
        private commonService: CommonService,
    ) {
    }

    FAQSection: string = 'SETARI_PAGE';
    termsSlug: string = 'termeni-si-conditii';
    hide: boolean = true;
    hide_confirmation: boolean = true;

    listAddresses: any = {
        domiciliu: [],
        sediu: [],
        resedinta: [],
    };
    addresses: Address[] = [{ value: 'domiciliu', viewValue: 'Domiciliu' }];
    user: any = {};
    judete!: Generic[];
    selectedAddress = this.addresses[0].value;

    hideOldPass = true;
    hidePass = true;
    hidePassConfirm = true;
    matcher = new MyErrorStateMatcher();
    form: FormGroup = new FormGroup({
        f_nume: new FormControl('', []),
        f_reg_com: new FormControl('', []),
        ci_serie: new FormControl('', [Validators.required]),
        ci_numar: new FormControl('', [Validators.required]),
        nume: new FormControl(
            '',
            Validators.compose([
                Validators.minLength(
                    environment.config.validatorsAccrossApp.minStringLength
                ),
                Validators.maxLength(
                    environment.config.validatorsAccrossApp.maxStringLength
                ),
                Validators.required,
            ])
        ),
        prenume: new FormControl(
            '',
            Validators.compose([
                Validators.minLength(
                    environment.config.validatorsAccrossApp.minStringLength
                ),
                Validators.maxLength(
                    environment.config.validatorsAccrossApp.maxStringLength
                ),
                Validators.required,
            ])
        ),
        telefon: new FormControl('', Validators.compose([Validators.required])),
        email: new FormControl('', []),
    });

    formPasswords: FormGroup = new FormGroup({
        old_password: new FormControl(''),
        password: new FormControl(
            '',
            Validators.compose([
                Validators.minLength(
                    environment.config.validatorsAccrossApp.minPassword
                ),
                Validators.maxLength(
                    environment.config.validatorsAccrossApp.maxPassword
                ),
                Validators.required,
                this.containsNumberValidator(),
                this.containsSpecialCharValidator(),
                this.containsUpperCaseValidator(),
            ])
        ),
        password_confirmation: new FormControl(
            '',
            Validators.compose([
                Validators.minLength(
                    environment.config.validatorsAccrossApp.minPassword
                ),
                Validators.maxLength(
                    environment.config.validatorsAccrossApp.maxPassword
                ),
                Validators.required,
                this.passwordMatchValidator(),
                this.containsNumberValidator(),
                this.containsSpecialCharValidator(),
                this.containsUpperCaseValidator(),
            ])
        ),
    });

    get passwordNewAccount() {
        return this.formPasswords.get('password');
    }
    get passwordConfirmationNewAccount() {
        return this.formPasswords.get('password_confirmation');
    }

    formAddress: FormGroup = new FormGroup({
        judet: new FormControl(
            '-1',
            Validators.compose([notDefaultValidator('-1')])
        ),
        type: new FormControl(
            '-1',
            Validators.compose([notDefaultValidator('-1')])
        ),
        oras: new FormControl(
            '',
            Validators.compose([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(
                    environment.config.validatorsAccrossApp.maxStringLength
                ),
            ])
        ),
        sector: new FormControl(null, []),
        strada: new FormControl(
            '',
            Validators.compose([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(
                    environment.config.validatorsAccrossApp.maxStringLength
                ),
            ])
        ),
        numar: new FormControl('', Validators.compose([Validators.required])),
        bloc: new FormControl('', []),
        scara: new FormControl('', []),
        etaj: new FormControl('', []),
        apartament: new FormControl('', []),
        observatii: new FormControl('', []),
        id_adresa: new FormControl('', []),
        adresa_type: new FormControl('1', [Validators.required]),
        cod_postal: new FormControl('', []),
    });

    passwordMatchValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const formPasswords = control.parent;
            if (!formPasswords) return null;

            const password = formPasswords.get('password')?.value;
            const confirmPassword = control.value;

            return password === confirmPassword ? null : { mismatch: true };
        };
    }

    updateFormShow: boolean = false;
    updateFormShowAddress: boolean = false;
    isAddressUpdate: boolean = false;

    public loadingAccount = false;
    public loadingAddresses = false;
    public loadingPassword = false;
    // general error
    errorTitle: string = environment.config.customNotifications.headers.error;
    errorIcon: string = environment.config.customNotifications.icons.error;
    errorType: string = environment.config.customNotifications.icons.error;
    // general success alert
    successTitle: string = environment.config.customNotifications.headers.success;
    successIcon: string = environment.config.customNotifications.icons.success;
    successType: string = environment.config.customNotifications.icons.success;

    // phone
    optionRom: ICountryOption = {
        name: 'Romania',
        flag: '🇷🇴',
        code: 'RO',
        dial_code: '+40',
    };

    currentOption = this.optionRom;
    options: ICountryOption[] = countriesList;

    changeCountry(option: ICountryOption) {
        this.currentOption = option;
    }
    // Validator for at least one number character
    containsNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const hasNumber = /\d/.test(control.value);
            return hasNumber ? null : { noNumber: true };
        };
    }

    containsUpperCaseValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const hasUpperCase = /[A-Z]/.test(control.value);
            return hasUpperCase ? null : { noUpperCase: true };
        };
    }

    // Validator for at least one special character
    containsSpecialCharValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(control.value);
            return hasSpecialChar ? null : { noSpecialChar: true };
        };
    }

    // user account form
    get nume() {
        return this.form.get('nume');
    }

    get prenume() {
        return this.form.get('prenume');
    }

    get email() {
        return this.form.get('email');
    }

    get telefon() {
        return this.form.get('telefon');
    }

    get ci_serie() {
        return this.form.get('ci_serie');
    }

    get ci_numar() {
        return this.form.get('ci_numar');
    }

    // address form

    get type() {
        return this.formAddress.get('type');
    }

    get judet() {
        return this.formAddress.get('judet');
    }

    get oras() {
        return this.formAddress.get('oras');
    }

    get strada() {
        return this.formAddress.get('strada');
    }

    get numar() {
        return this.formAddress.get('numar');
    }

    get codPostal() {
        return this.formAddress.get('cod_postal');
    }

    // update pass form
    get oldPassword() {
        return this.formPasswords.get('old_password');
    }

    get password() {
        return this.formPasswords.get('password');
    }

    get passwordConfirmation() {
        return this.formPasswords.get('password_confirmation');
    }

    loadJudete() {
        this.commonService
            .getJudete()
            .pipe(
                map((results: any) => [...results]))
            .subscribe({
                next: (results) => {
                    this.judete = results;
                },
                error: (error) => {
                    console.error('Error loading judete', error);
                }
            })
    }

    openSolicitare() {
        let configData = {
            width: '80%',
            height: '70%',
            minWidth: '500px',
        };

        this.breakpointObserver
            .observe([
                Breakpoints.XSmall, // Matches screens smaller than 600px width
                Breakpoints.Small, // Matches screens between 600px and 959px width
            ])
            .subscribe((result) => {
                if (result.breakpoints[Breakpoints.XSmall]) {
                    // If the screen is XSmall (smaller devices)
                    configData.width = '95%';
                    configData.minWidth = '300px';
                } else if (result.breakpoints[Breakpoints.Small]) {
                    // If the screen is Small (medium devices)
                    configData.width = '80%';
                    configData.minWidth = '500px';
                }

                let dialogData = {
                    ...configData,
                    data: { PageSection: this.FAQSection },
                };

                const dialogRef = this.dialog.open(SuportOnlineComponent, dialogData);

                dialogRef.afterClosed().subscribe((res: any) => {
                    const response =
                        typeof res.data !== 'undefined' &&
                            res.data !== null &&
                            typeof res.data.id !== 'undefined'
                            ? res.data
                            : null;

                    if (response !== null) {
                        let message =
                            'Solicitarea dvs cu numarul ' +
                            response.id +
                            '/' +
                            response.date +
                            ' a fost trimisa cu succes. ';
                        this.notificationService.warningSwal(
                            this.successTitle,
                            message,
                            this.successIcon
                        );
                    }
                });
            });
    }

    ngOnInit(): void {
        this.initPasswordForm();
        this.getAccountDetails();
        this.listAddress();
        this.loadJudete();
    }

    getAccountDetails() {
        this.loadingAccount = true;

        this.userService
            .findDetails()
            .pipe(finalize(() => (this.loadingAccount = false)))
            .subscribe({
                next: (res: any) => {
                    let response =
                        typeof res.status_code !== 'undefined' ? res : res.error;
                    if (typeof response.status_code !== 'undefined') {
                        if (
                            response.status_code == 200 &&
                            typeof response.data !== 'undefined'
                        ) {
                            localStorage.setItem(
                                environment.config.userKey,
                                JSON.stringify(response.data.user)
                            );

                            // EVENT COMMUNICATION : trigger the user updated event
                            this.eventService.getEvent(USER_UPDATED).next(true);

                            this.user = response.data.user;
                        }
                    } else {
                        // add sentry
                        let errorMessage =
                            environment.config.customNotifications.generalMessages.error;
                        this.notificationService.warningSwal(
                            this.errorTitle,
                            errorMessage,
                            this.errorIcon
                        );
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this.onHttpError(err.error);
                },
            });
    }

    initPasswordForm() {
        this.formPasswords = this.formsBuilder.group({
            old_password: ['', [Validators.required]],
            password: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(50),
                    this.containsNumberValidator(),
                    this.containsSpecialCharValidator(),
                    this.containsUpperCaseValidator(),
                ],
            ],
            password_confirmation: [
                '',
                [
                    Validators.required,
                    Validators.minLength(8),
                    Validators.maxLength(50),

                    this.passwordMatchValidator(),
                    this.containsNumberValidator(),
                    this.containsSpecialCharValidator(),
                    this.containsUpperCaseValidator(),
                ],
            ],
        });
    }

    listAddress() {
        this.loadingAddresses = true;

        this.userService
            .addressList()
            .pipe(finalize(() => (this.loadingAddresses = false)))
            .subscribe({
                next: (res: any) => {

                    this.listAddresses = res.data;
                },
                error: (err: any) => {
                    console.log(err);
                },
            });
    }

    isAddressFormValid() {
        this.formAddress.markAllAsTouched();
        this.formAddress.updateValueAndValidity();

        return this.formAddress.valid;
    }

    addAddress() {
        console.log(this.formAddress.value);

        if (!this.isAddressFormValid()) {
            return;
        }

        var self = this;
        this.loadingAddresses = true;
        try {
            this.userService
                .addressSave(self.formAddress.value)
                .pipe(finalize(() => (this.loadingAddresses = false)))
                .subscribe({
                    next: (res: any) => {
                        //self.getAccountDetails();
                        self.openToast({
                            title: self.successTitle,
                            message: 'Contul dvs a fost actualizat cu succes.',
                            type: self.successIcon,
                        });
                        self.toggleFormAddress();
                        self.listAddress();
                    },
                    error: (err: any) => {
                        console.log(err);
                        this.onHttpError(err.error);
                    },
                });
        } catch (err) {
            self.loadingAddresses = false;
            console.log(err);
            self.notificationService.warningSwal(
                this.errorTitle,
                'Am intampinat o problema in procesarea informatiilor dvs. Va rugam sa reincercati sau sa reveniti mai tarziu.',
                this.errorIcon
            );
        }
    }

    updateAddress() {
        console.log(this.formAddress.value);

        if (!this.isAddressFormValid()) {
            return;
        }

        var self = this;
        this.loadingAddresses = true;
        try {
            this.userService
                .addressUpdate(self.formAddress.value)
                .pipe(finalize(() => (this.loadingAddresses = false)))
                .subscribe({
                    next: (res: any) => {
                        //self.getAccountDetails();
                        self.openToast({
                            title: self.successTitle,
                            message: 'Adresa a fost actualizata cu succes.',
                            type: self.successIcon,
                        });
                        self.toggleFormAddress();
                        self.listAddress();
                    },
                    error: (err: any) => {
                        console.log(err);
                        this.onHttpError(err.error);
                    },
                });
        } catch (err) {
            self.loadingAddresses = false;
            console.log(err);
            self.notificationService.warningSwal(
                this.errorTitle,
                'Am intampinat o problema in procesarea informatiilor dvs. Va rugam sa reincercati sau sa reveniti mai tarziu.',
                this.errorIcon
            );
        }
    }

    deleteAddress(addressId: number) {
        this.loadingAddresses = true;
        this.userService
            .addressDelete(addressId)
            .pipe(finalize(() => (this.loadingAddresses = false)))
            .subscribe({
                next: (res: any) => {
                    console.log(res);
                    this.openToast({
                        title: this.successTitle,
                        message: 'Adresa mentionata a fost stearsa.',
                        type: this.successIcon,
                    });
                    this.listAddress();
                },
                error: (err: any) => {
                    console.log(err);
                    this.onHttpError(err.error);
                },
            });
    }

    toggleFormAddress() {
        this.type?.enable();
        this.isAddressUpdate = false;
        if (!this.updateFormShowAddress) {
            // we need to show the form and process it
            this.formAddress.reset({
                judet: '-1',
                oras: '',
                type: this.user.type == 1 ? '1' : '-1',
                sector: null,
                strada: '',
                numar: '',
                bloc: '',
                scara: '',
                etaj: '',
                apartament: '',
                observatii: '',
                id_adresa: '',
                cod_postal: '',
                adresa_type: '1',
            });
        }

        this.updateFormShowAddress = !this.updateFormShowAddress;
    }

    updateFormAddress(address: any) {
        this.isAddressUpdate = true;

        this.formAddress.patchValue({
            judet: address.judet ? Number(address.judet) : '',
            oras: address.oras,
            type: address.type ? address.type.toString() : '',
            sector: address.sector ? address.sector.toString() : null,
            strada: address.strada,
            numar: address.numar,
            bloc: address.bloc,
            scara: address.scara,
            etaj: address.etaj,
            apartament: address.apartament,
            observatii: address.observatii,
            id_adresa: address.id,
            cod_postal: address.cod_postal,
        });
        this.updateFormShowAddress = true;

        this.type?.disable();
    }

    async onUpdatePassword() {
        var self = this;
        this.loadingPassword = true;
        if (!this.formPasswords.valid) {
            this.loadingPassword = false;
            await self.notificationService.warningSwal(
                this.errorTitle,
                'Toate campurile sunt obligatorii',
                this.errorIcon
            );
        } else if (
            this.formPasswords.value.password !==
            this.formPasswords.value.password_confirmation
        ) {
            this.loadingPassword = false;
            await self.notificationService.warningSwal(
                this.errorTitle,
                'Parolele introduse nu corespund pentru actualizare',
                this.errorIcon
            );
        } else {
            // we have a go?
            this.userService
                .updatePasswordAccount(this.formPasswords.value)
                .pipe(finalize(() => (this.loadingPassword = false)))
                .subscribe({
                    next: (res: any) => {
                        let response =
                            typeof res.status_code !== 'undefined' ? res : res.error;
                        if (typeof response.status_code !== 'undefined') {
                            console.log(response, 'response here');
                            if (
                                response.status_code == 200 &&
                                typeof response.data !== 'undefined'
                            ) {
                                // success
                                this.clearFormPassword();
                                self.openToast({
                                    title: self.successTitle,
                                    message:
                                        'Felicitari. Actualizarea parolei a fost efectuata cu succes.',
                                    type: self.successType,
                                });
                            } else {
                                // add sentry
                                let errorMessage =
                                    environment.config.customNotifications.generalMessages.error;
                                self.loadingPassword = false;
                                self.notificationService.warningSwal(
                                    self.errorTitle,
                                    errorMessage,
                                    self.errorIcon
                                );
                            }
                        }
                    },
                    error: (err: any) => {
                        console.log(err);
                        this.onHttpError(err.error);
                    },
                });
        }
    }

    clearFormPassword() {
        this.oldPassword?.setValue('');
        this.password?.setValue('');
        this.passwordConfirmation?.setValue('');

        this.oldPassword?.setErrors(null);
        this.password?.setErrors(null);
        this.passwordConfirmation?.setErrors(null);

        this.formPasswords.markAsUntouched();
    }

    toggleForm() {
        if (!this.updateFormShow) {
            this.pushInfoForm();
        }

        this.updateFormShow = !this.updateFormShow;
    }

    pushInfoForm() {
        var self = this;
        const data = localStorage.getItem(environment.config.userKey);
        if (!data) {
            console.log('User data not in localstorage');
            return;
        }

        let json = JSON.parse(data);

        const countryOption = countriesList.find(
            (c: any) => c.code.toLowerCase() == json.iso_code.toLowerCase()
        );
        self.currentOption = countryOption ? countryOption : this.optionRom;
        console.log(this.currentOption);
        self.user.nume = json.nume;
        self.user.prenume = json.prenume;
        self.user.email = json.email;
        self.user.telefon = json.telefon;
        self.user.cnp = json.cnp;

        self.user.dialcode = json.dialcode;
        self.user.iso_code = json.iso_code;
        self.user.international_number = json.international_number;

        self.user.ci_serie = json.ci_serie;
        self.user.ci_numar = json.ci_numar;

        self.user.type = json.type;

        if (typeof json.f_nume !== 'undefined') {
            self.form.patchValue({
                f_nume: json.f_nume,
                f_reg_com: json.f_reg_com,
            });
            self.user.f_nume = json.f_nume;
            self.user.f_reg_com = json.f_reg_com;
        } else {
            self.user.f_reg_com = '';
            self.user.f_nume = '';
        }

        console.log(self.user);

        // now we need to update all the params inside the form
        let telefon = json.iso_code == 'ro' ? json.telefon.slice(1) : json.telefon;
        self.form.patchValue({
            nume: json.nume,
            prenume: json.prenume,
            email: json.email,
            telefon: telefon,
            ci_serie: json.ci_serie,
            ci_numar: json.ci_numar,
        });

        console.log(self.user, 'check user please');
    }

    updateAccount() {
        var self = this;
        this.loadingAccount = true;
        var phone;

        try {
            let emailReg = new RegExp(environment.config.emailRegex);
            if (
                !emailReg.test(this.form.value.email) ||
                this.form.value.email.length > 70
            ) {
                self.loadingAccount = false;
                self.notificationService.warningSwal(
                    this.errorTitle,
                    'Vă rugăm să introduceți o adresă de email validă.',
                    this.errorIcon
                );
                return;
            }

            if (this.form.value.telefon == null || this.form.value.telefon == '') {
                self.loadingAccount = false;
                self.notificationService.warningSwal(
                    this.errorTitle,
                    'Numarul de telefon este obligatoriu',
                    this.errorIcon
                );
                return;
            } else {
                const telephoneNoInput =
                    this.currentOption.dial_code + this.form.value.telefon;
                const phoneNo = phoneNumberUtil.parseAndKeepRawInput(telephoneNoInput);
                let regionCodeLower = '';
                let nationalNumberString = '';
                if (phoneNo) {
                    const regionCode = phoneNumberUtil.getRegionCodeForNumber(phoneNo);
                    if (regionCode) {
                        regionCodeLower = (regionCode as string).toLowerCase();
                    }

                    const nationalNumber = phoneNo.getNationalNumber();
                    if (nationalNumber) {
                        nationalNumberString = nationalNumber.toString();
                    }
                }

                phone = {
                    dialCode: '',
                    internationalNumber: telephoneNoInput,
                    isoCode: regionCodeLower,
                    nationalNumber: nationalNumberString,
                };

                phone.dialCode = telephoneNoInput.replace(phone.nationalNumber, '');
                phone.nationalNumber =
                    phone.isoCode == 'ro'
                        ? '0' + phone.nationalNumber
                        : phone.nationalNumber;
            }

            if (
                this.user.cnp.toString().length < 13 &&
                (!self.form.value.f_nume || self.form.value.f_nume.length < 2)
            ) {
                // Company Required
                self.loadingAccount = false;
                self.notificationService.warningSwal(
                    this.errorTitle,
                    "Vă rugăm să completați câmpul 'Denumire Firma'",
                    this.errorIcon
                );
                return;
            }

            if (
                this.user.cnp.toString().length < 13 &&
                (!self.form.value.f_reg_com || self.form.value.f_reg_com.length < 2)
            ) {
                // Company Required
                self.loadingAccount = false;
                self.notificationService.warningSwal(
                    this.errorTitle,
                    "Vă rugăm să completați câmpul 'Registrul Comertului'",
                    this.errorIcon
                );
                return;
            }

            const dataObj = Object.assign({}, self.form.value, { phone: phone });
            this.userService
                .updateInfoAccount(dataObj)
                .pipe(finalize(() => (this.loadingAccount = false)))
                .subscribe({
                    next: (res: any) => {
                        let response =
                            typeof res.status_code !== 'undefined' ? res : res.error;
                        if (typeof response.status_code !== 'undefined') {
                            if (
                                response.status_code == 200 &&
                                typeof response.data !== 'undefined'
                            ) {
                                self.getAccountDetails();
                                let obj = {
                                    title: self.successTitle,
                                    message: 'Contul dvs a fost actualizat cu succes.',
                                    type: self.successIcon,
                                };
                                self.openToast(obj);
                                self.toggleForm();
                            }
                        } else {
                            // add sentry
                            let errorMessage =
                                environment.config.customNotifications.generalMessages.error;
                            self.notificationService.warningSwal(
                                self.errorTitle,
                                errorMessage,
                                self.errorIcon
                            );
                        }
                    },
                    error: (err: any) => {
                        console.log(err);
                        this.onHttpError(err.error);
                    },
                });
        } catch (err) {
            self.loadingAccount = false;
            console.log(err);
            self.notificationService.warningSwal(
                this.errorTitle,
                'Am intampinat o problema in procesarea informatiilor dvs. Va rugam sa reincercati sau sa reveniti mai tarziu.',
                this.errorIcon
            );
        }
        return;
    }

    openToast(object: any) {
        const { type, message, title } = object;

        let options = this.toastr.toastrConfig;
        options.progressBar = true;
        options.preventDuplicates = true;
        options.closeButton = true;

        const inserted = this.toastr.show(
            message,
            title,
            options,
            options.iconClasses[type]
        );
    }

    onHttpError(res: any, showErrorsInToast: boolean = false) {
        if (res.errors && res.errors.message && res.errors.message.length > 0) {
            res.errors.message.forEach((msg: string) => {
                if (showErrorsInToast) {
                    this.openToast({
                        title: this.errorTitle,
                        message: msg,
                        type: this.errorType,
                    });
                } else {
                    this.notificationService.warningSwal(
                        this.errorTitle,
                        msg,
                        this.errorIcon
                    );
                }
            });
        } else {
            let errorMessage =
                environment.config.customNotifications.generalMessages.error;
            this.notificationService.warningSwal(
                this.errorTitle,
                errorMessage,
                this.errorIcon
            );
        }
    }

    openTermsModal(slug: string): void {
        let configData = Object.assign({}, this.dialogService.getDialogConfig(), {
            data: {
                slug: slug,
            },
        });
        const dialogRef = this.dialog.open(
            TermeniConditiiModalComponent,
            configData
        );
    }

    getPasswordTooltip(): string {
        const control = this.formPasswords.controls['password'];
        if (!control.errors) return '';

        let errorMessage = '';

        if (control.hasError('minlength')) {
            errorMessage += 'Minim 8 caractere. ';
        }
        if (control.hasError('maxlength')) {
            errorMessage += 'Max 50 caractere. ';
        }
        if (control.hasError('noSpecialChar')) {
            errorMessage += 'Minim 1 caracter special. ';
        }
        if (control.hasError('noNumber')) {
            errorMessage += 'Minim 1 cifră. ';
        }

        return errorMessage.trim();
    }
}
