import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Solicitare } from '../interfaces/request.interface';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class StoreService {

	private _user: BehaviorSubject<any> = new BehaviorSubject<any>(Object.create({}));
	user$: Observable<any> = this._user.asObservable();

	private requestDataSubject = new BehaviorSubject<Solicitare | null>(null);

	constructor() {
		const loggedInUser = localStorage.getItem(environment.config.userKey);
		if (loggedInUser) this.setUser(JSON.parse(loggedInUser));
	}

	getUser() {
		return this._user.getValue();
	}

	setUser(user: any) {
		this._user.next({ ...this.getUser(), ...user });
		localStorage.setItem(environment.config.userKey, JSON.stringify(user));
	}

	setRequestData(data: Solicitare) {
		this.requestDataSubject.next(data);
	}

	getRequestData() {
		return this.requestDataSubject.asObservable();
	}
}
