<div class="card">
    <div class="card__header">
        <div class="card__header__icon" *ngIf="isGeneral"><mat-icon>info</mat-icon></div>
        <div class="card__header__icon" *ngIf="isPerson"><mat-icon>person</mat-icon></div>
        <div class="card__header__icon" *ngIf="isPasswordSetting"><mat-icon>settings</mat-icon></div>
        <div class="card__header__icon" *ngIf="isAddress"><mat-icon> location_on</mat-icon></div>

        <div class="card__header__title"> {{ (cardTitle ? cardTitle : "&nbsp;") | titlecase }}</div>

        <button class="card__header__button" *ngIf="hasButton">
            <mat-icon class="icon">{{ iconName }}</mat-icon>
            <span>{{buttonName | uppercase}}</span>
        </button>
    </div>

    <mat-divider></mat-divider>

    <div class="card__content">
        <div class="card__content__person" *ngIf="isPerson">
            <!-- <div class="row">
                <div *ngFor="let row of personRows">
                    <div class="data"> {{row}}</div>
                    <mat-divider></mat-divider>
                </div>
            </div>
            <div class="info">
                <div *ngFor="let info of personInfo">
                    <div class="data"> {{info}} </div>
                </div>
            </div> -->

            <div class="data" *ngFor="let data of dataPerson">
                <div class="data__field">{{data.field}}</div>
                <div class="data__value">{{data.value}}</div>
            </div>
        </div>

        <div class="card__content__general" *ngIf="isAddress">
            
        </div>
    </div>
</div>