import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsCardComponent } from './settings-card.component';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
  declarations: [SettingsCardComponent],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [SettingsCardComponent],
})
export class SettingsCardModule { }
