import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LayoutService } from 'src/app/services/layout.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  dialogRef: MatDialogRef<any> | undefined;

  constructor(private layoutService: LayoutService) {}
  
  getDialogConfig() {
    return {
      minHeight: this.layoutService.isHBP1() ? 850 : 640,
      maxHeight: this.layoutService.isHBP1() ? 850 : 640,
      minWidth: this.layoutService.isBP()
      ? 230
      : this.layoutService.isXS()
        ? 350
        : this.layoutService.isSM()
          ? 400
          : this.layoutService.isMD() 
            ? 550
            : this.layoutService.isLG()
              ? 800
              : this.layoutService.isXL() 
                ? 950
                : 800,
      maxWidth: this.layoutService.isBP()
      ? 230
      : this.layoutService.isXS()
        ? 350
        : this.layoutService.isSM()
          ? 400
          : this.layoutService.isMD() 
            ? 550
            : this.layoutService.isLG()
              ? 800
              : this.layoutService.isXL() 
                ? 950
                : 800,
      autoFocus: false,
      disableClose: true,
    }
  }

  closeDialog() {
    this.dialogRef?.close();
  }
}
