export enum DocumentsList {
    ITEM_1_1 = 'Cerere pentru emiterea certificatului de urbanism',
    ITEM_1_2 = 'Cerere pentru prelungirea valabilității certificatului de urbanism',
    ITEM_1_3 = 'Cerere pentru emiterea autorizației de construire / desființare',
    ITEM_1_4 = 'Cerere pentru dispoziție de șantier',
    ITEM_1_5 = 'Cerere pentru prelungirea valabilității autorizației de construire / desfiintare',
    ITEM_1_6 = 'Cerere pentru regularizarea taxei datorate ca urmare a declarării valorii reale la terminarea lucrărilor',
    ITEM_1_7 = 'Cerere de participare in comisia de recepție la terminarea lucrărilor de construcții',
    ITEM_1_8 = 'Cerere pentru Emiterea CERTIFICATULUI DE ATESTARE A EDIFICĂRII CONSTRUCȚIEI',
    ITEM_1_9 = 'Cerere pentru emitere acord / autorizație administrator drum',
    ITEM_1_10 = 'Cerere adresă schimbare destinație', // Cerere pentru emiterea certificatului de urbanism (SCHIMBARE DE DESTINAȚIE)
    ITEM_1_11 = 'Adrese/Altele',
    ITEM_2_1 = 'Aviz Primar',
    ITEM_2_2 = 'Cerere pentru emiterea Avizului de Amplasare',
    ITEM_2_3 = 'Cerere pentru avizarea studiului de amplasare a mijloacelor publicitare',
    ITEM_3_1 = 'Cerere pentru avizarea / aprobarea documentației de urbanism PUD (plan Urbanistic de Detaliu)',
    ITEM_4_1 = 'Comunicare privind incepere execuție lucrări',
    ITEM_4_2 = 'Comunicare privind incheiere execuție lucrări',
}

export enum DocumentsListUtile {
    ITEM_1 = 'Cerere pentru emiterea autorizației de construire / desființare',
    ITEM_2 = 'Comunicare privind incepere execuție lucrări',
    ITEM_3 = 'Comunicare privind incheiere execuție lucrări',
    ITEM_4 = 'Cerere pentru prelungirea valabilității certificatului de urbanism',
    ITEM_5 = 'Cerere pentru emiterea certificatului de urbanism',
    ITEM_6 = 'Cerere pentru prelungirea valabilității autorizației de construire / desfiintare',
    ITEM_7 = 'Cerere pentru regularizarea taxei datorate ca urmare a declarării valorii reale la terminarea lucrărilor',
    ITEM_8 = 'Cerere pentru emiterea Avizului de Amplasare',
    ITEM_9 = 'Cerere pentru emitere acord / autorizație administrator drum',
    ITEM_10 = 'Cerere de admitere a certificatului de atestare a edificării construcției',
    ITEM_11 = 'Solicitare de participare în comisia de recepție la terminarea lucrărilor de construcții însoțită de opis',
    ITEM_12 = 'Cerere pentru avizarea / aprobarea documentației de urbanism p.u.d.',
    ITEM_13 = 'Cerere adresă schimbare destinație', // Cerere pentru emiterea certificatului de urbanism (SCHIMBARE DE DESTINAȚIE)
    ITEM_14 = 'Cerere pentru avizarea studiului de amplasare a mijloacelor publicitare',
}