<app-accessibility-toolbar *ngIf="portalLive == true"></app-accessibility-toolbar>

<div class="registre-container">
    <div class="registre-welcome-card d-flex flex-column align-items-start align-self-stretch">
        <div class="registre-welcome-card-content d-flex justify-content-between align-items-center align-self-stretch">
            <span>Mai jos puteți găsi o listă cu registre.</span>
        </div>
    </div>
    <div class="registre-content">
        <div class="registre-content-container">
            <form [formGroup]="paramsForm">
                <div class="row">
                    <div class="col-md">
                        <p class="label-text">Tip Registru</p>
                        <mat-form-field>
                            <mat-select formControlName="type" (selectionChange)="onTypeChange($event.value)">
                                <mat-option *ngFor="let registru of registre$ | async" [value]="registru.slug">
                                    {{ registru.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md">
                        <p class="label-text">An valabilitate/Emitere Document</p>
                        <mat-form-field>
                            <mat-select formControlName="valabilitate"
                                (selectionChange)="onValabilitateChange($event.value)">
                                <mat-option *ngFor="let option of valabilitateFilteringOptions" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <div class="table-container" [ngClass]="{ 'hide-table': selectedSlug === '-1' || isLoading}">
                <table id="datatable" class="row-border hover custom-dt w-100" datatable [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger"></table>
            </div>
            <div class="text-center" *ngIf="selectedSlug === '-1' && !isLoading">
                <span class="noType-text">Selectați un tip de registru</span>
            </div>
            <div class="section-spinner" *ngIf="isLoading">
                <div class="spinner d-flex flex-column justify-content-center align-items-center">
                    <mat-spinner></mat-spinner>
                    <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
                </div>
            </div>
        </div>
    </div>

    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>