import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesResponse } from 'src/app/components/solicitari/data-table-response.interface';
import { environment } from 'src/environments/environment';
import { Observable, Subject, map } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { helper } from 'src/environments/helper';
import { Generic } from 'src/app/interfaces/generic.interface';
import { RegistreService } from './registre.service';

interface Register { id: number, name: string, slug: string }
@Component({
    selector: 'app-registre',
    templateUrl: './registre.component.html',
    styleUrls: ['./registre.component.scss']
})
export class RegistreComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;

    portalLive : boolean = environment.portalLive;
    
    isLoading = false;
    helper = helper;

    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dtInstance!: DataTables.Api;

    dtLink = environment.interop.urbanismCore.basePath + environment.interop.urbanismCore.registre.getRegistreDT;

    registre$!: Observable<Register[]>;

    paramsForm!: FormGroup;

    defaultParameter: { name: string; slug: string } = {
        name: '-- Alegeți --',
        slug: '-1',
    }

    valabilitateFilteringOptions: string[] = [
        // '2020',
        // '2021',
        // '2022',
        // '2023',
        '2024',
    ];

    selectedSlug: string = '-1';
    selectedValabilitate: string = '2024';

    constructor(
        private http: HttpClient,
        private registreService: RegistreService,
    ) { }

    ngOnInit(): void {
        this.initParamsForm();
        this.renderDT();
        this.loadRegistre();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next('');
    }

    initParamsForm() {
        this.paramsForm = new FormGroup({
            type: new FormControl('-1'),
            valabilitate: new FormControl('2024')
        })
    }

    onTypeChange(selectedType: string) {
        this.selectedSlug = selectedType;
        if (selectedType !== '-1') {
            this.rerenderDT();
        } else {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.clear().destroy();
                this.dtTrigger.next('');
            });
        }
    }

    onValabilitateChange(selectedValabilitate: string) {
        this.selectedValabilitate = selectedValabilitate;
        if (this.selectedSlug !== '-1') {
            this.rerenderDT();
        } else {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.clear().destroy();
                this.dtTrigger.next('');
            });
        }
    }

    loadRegistre() {
        this.registre$ = this.registreService
            .getRegistre()
            .pipe(map((res: any) => [this.defaultParameter, ...res]));
    }

    renderDT() {
        this.isLoading = true;
        this.dtOptions = {
            responsive: true,
            serverSide: true,
            searching: false,
            ajax: (dataTablesParameters: any, callback: any) => {
                this.isLoading = true;
                dataTablesParameters.slug = this.selectedSlug;
                dataTablesParameters.valabilitate = this.selectedValabilitate;

                this.http
                    .post<any>(
                        this.dtLink,
                        dataTablesParameters
                    )
                    .subscribe((res: any) => {
                        this.isLoading = false;
                        callback({
                            recordsTotal: res.data.recordsTotal,
                            recordsFiltered: res.data.recordsFiltered,
                            data: res.data.data,
                        });
                    });
            },
            language: environment.config.romanianLanguageDataTable,
            lengthMenu: [100],
            columns: [
                {
                    title: 'Crt.',
                    data: 'id',
                },
                {
                    title: 'Denumire companie',
                    data: 'solicitant',
                },
                {
                    title: 'Punct de lucru',
                    data: 'strada_name',
                },
                {
                    title: 'CUI',
                    data: 'cnp_cif',
                },
                {
                    title: 'Valabilitate',
                    data: 'valability',
                    render: (data: any, type: any, full: any) => {
                        if (type === 'display' && data) {
                            const dateParts = data.split('-');
                            return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
                        }
                        return data;
                    },
                },
                {
                    title: 'Nr. acord/autorizație',
                    data: 'numar_certificat',
                },
                {
                    title: 'Data emitere acord/autorizație',
                    data: 'document_data',
                }
            ],
        };
    }

    rerenderDT(paginate: boolean = false): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw(paginate);
        });
    }

    // rerenderDT(paginate: boolean = false): void {
    //     this.isLoading = true;
    //     this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //         dtInstance.clear().draw();

    //         dtInstance.one('draw', () => {
    //             this.isLoading = false;
    //         }).draw(paginate);
    //     });
    // }
}
