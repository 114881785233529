<div class="articol">
    <ng-container *ngIf="!isLoading">
        <div class="d-flex justify-content-center">
            <div class="image-container d-flex flex-column align-items-start align-self-stretch">
                <img class="image d-flex" src="{{ articleDetails.image_url }}" />
                <span>{{ formatDate(articleDetails.last_updated) }}</span>
            </div>
        </div>
        <div class="content d-flex flex-column align-items-center align-self-stretch">
            <span class="content-title">{{ articleDetails.title }}</span>
            <span class="content-text" [innerHTML]="articleDetails.content"></span>
        </div>
    </ng-container>
    <ng-container *ngIf="isLoading">
        <div class="spinner d-flex flex-column justify-content-center align-items-center mt-3">
            <mat-spinner></mat-spinner>
            <h3 class="mt-2">{{ helper.dialogConfig.generalMessages.pleaseWait }}</h3>
        </div>
    </ng-container>
    <app-footer></app-footer>
</div>