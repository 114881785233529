import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, map, catchError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SolicitareType } from './models';
import { NotificationService } from 'src/app/services/notification.service';

@Injectable({
    providedIn: 'root',
})
export class SolicitariService {
    private token!: string;
    private authCorePath = environment.interop.authCore.basePath;
    private httpOptions: any;

    successTitle: string = environment.config.customNotifications.headers.success;
    successIcon: string = environment.config.customNotifications.icons.success;

    constructor(private http: HttpClient, private notificationService: NotificationService) { }

    private getListsDTUrl = this.authCorePath + environment.interop.authCore.solicitare.listDT;
    private updateListUrl = this.authCorePath + environment.interop.authCore.solicitare.updateURL;
    private storeListUrl = this.authCorePath + environment.interop.authCore.solicitare.storeURL;

    // auth-core
    private viewSolicitareAuthUrl = environment.interop.authCore.solicitare.viewSolicitare;
    private saveMessageSolicitareAuthUrl = environment.interop.authCore.solicitare.saveMessageSolicitare;
    private fileUploadRegisterAuthUrl = environment.interop.authCore.user.fileUploadRegister;

    // dgitl-core
    private viewSolicitareDgitlUrl = environment.interop.dgitlCore.api.cetatean.viewSolicitare;
    private saveMessageSolicitareDgitlUrl = environment.interop.dgitlCore.api.cetatean.saveMessageSolicitare;
    private fileUploadRegisterDgitlUrl = environment.interop.dgitlCore.api.cetatean.uploadFileRegister;

    // autcom-core
    private viewSolicitareAutcomUrl = environment.interop.autcomCore.api.cetatean.viewSolicitare;
    private saveMessageSolicitareAutcomUrl = environment.interop.autcomCore.api.cetatean.saveMessageSolicitare;
    private fileUploadRegisterAutcomUrl = environment.interop.autcomCore.api.cetatean.uploadFile;

    // programari
    private viewSolicitareProgramariUrl = environment.interop.programariCore.api.cetatean.viewSolicitare;
    private saveMessageSolicitareProgramariUrl = environment.interop.programariCore.api.cetatean.saveMessageSolicitare;
    private fileUploadRegisterProgramariUrl = environment.interop.programariCore.api.cetatean.uploadFile;

    getDT() {
        return this.http.get(this.getListsDTUrl);
    }

    updateDT(data: any) {
        return this.http.put(this.updateListUrl, data);
    }
    addList(data: any) {
        return this.http.post(this.storeListUrl, data);
    }

    viewSolicitare(solicitareType: string, solicitareId: number) {
        console.log(solicitareType, solicitareId);

        const solicitareTypeEnum = this.getSolicitareTypeEnum(solicitareType);
        console.log(solicitareTypeEnum);

        let viewSolicitareUrl = '';

        switch (solicitareTypeEnum) {
            case SolicitareType.Suport:
                viewSolicitareUrl = this.viewSolicitareAuthUrl
                break;
            case SolicitareType.DGITL:
                viewSolicitareUrl = this.viewSolicitareDgitlUrl
                break;
            case SolicitareType.AutorizariComerciale:
                viewSolicitareUrl = this.viewSolicitareAutcomUrl
                break;
            case SolicitareType.Programari:
                viewSolicitareUrl = this.viewSolicitareProgramariUrl
                break;
            default:
                break;
        }

        return this.http.get<{ data: any }>(this.getBaseUrlByType(solicitareType) + viewSolicitareUrl + solicitareId);
    }

    saveMessageSolicitare(solicitareType: string, solicitareId: number, data: any) {
        const solicitareTypeEnum = this.getSolicitareTypeEnum(solicitareType);
        let saveMessageSolicitareUrl = '';

        switch (solicitareTypeEnum) {
            case SolicitareType.Suport:
                saveMessageSolicitareUrl = this.saveMessageSolicitareAuthUrl
                break;
            case SolicitareType.DGITL:
                saveMessageSolicitareUrl = this.saveMessageSolicitareDgitlUrl
                break;
            case SolicitareType.AutorizariComerciale:
                saveMessageSolicitareUrl = this.saveMessageSolicitareAutcomUrl
                break;
            case SolicitareType.Programari:
                saveMessageSolicitareUrl = this.saveMessageSolicitareProgramariUrl
                break;
            default:
                break;
        }

        return this.http.post<{ data: any }>(this.getBaseUrlByType(solicitareType) + saveMessageSolicitareUrl + solicitareId, data);
    }

    fileUpload(solicitareType: string, formData: FormData) {
        const solicitareTypeEnum = this.getSolicitareTypeEnum(solicitareType);
        let fileUploadRegisterUrl = '';

        switch (solicitareTypeEnum) {
            case SolicitareType.Suport:
                fileUploadRegisterUrl = this.fileUploadRegisterAuthUrl
                break;
            case SolicitareType.DGITL:
                fileUploadRegisterUrl = this.fileUploadRegisterDgitlUrl
                break;
            case SolicitareType.AutorizariComerciale:
                fileUploadRegisterUrl = this.fileUploadRegisterAutcomUrl
                break;
            case SolicitareType.Programari:
                fileUploadRegisterUrl = this.fileUploadRegisterProgramariUrl
                break;
            default:
                break;
        }

        return this.http.post<{ data: any }>(this.getBaseUrlByType(solicitareType) + fileUploadRegisterUrl, formData);
    }

    private getBaseUrlByType(solicitareType: string) {
        const solicitareTypeEnum = this.getSolicitareTypeEnum(solicitareType);
        switch (solicitareTypeEnum) {
            case SolicitareType.Suport:
                return environment.interop.authCore.basePath;
            case SolicitareType.DGITL:
                return environment.interop.dgitlCore.basePath;
            case SolicitareType.AutorizariComerciale:
                return environment.interop.autcomCore.basePath;
            case SolicitareType.Programari:
                return environment.interop.programariCore.basePath;
            default:
                return '';
        }
    }

    private getSolicitareTypeEnum(solicitareType: string): SolicitareType {
        const solicitareTypeEnum: SolicitareType = SolicitareType[solicitareType as keyof typeof SolicitareType];
        return solicitareTypeEnum
    }

    generareDraft(url: string): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.notificationService
                .warningSwal(
                    'Generare draft cerere',
                    'Cererea dumneavoastră va fi generată fără fișierele încărcate (dacă există).',
                    environment.config.customNotifications.icons.warning,
                    'Confirmare',
                    'Anulare',
                    true
                )
                .then((result) => {
                    if (result.isConfirmed) {
                        this.http.get(url, { responseType: 'blob' as 'json' })
                            .pipe(
                                map((response: any) => {
                                    const blob = new Blob([response], { type: 'application/pdf' });
                                    const url = window.URL.createObjectURL(blob);
                                    window.open(url, '_blank');
                                    window.URL.revokeObjectURL(url);
                                    let message = 'Solicitarea dvs. a fost generată cu succes';

                                    this.notificationService.warningSwal(
                                        this.successTitle,
                                        message,
                                        this.successIcon
                                    );
                                    return true;
                                }),
                                catchError((error: any) => {
                                    this.notificationService.handleHttpError(error);
                                    return [false]
                                })
                            )
                            .subscribe({
                                next: (response: any) => observer.next(response),
                                complete: () => observer.complete
                            });
                    } else {
                        observer.next(false);
                        observer.complete();
                    }
                });
        });
    }
}
