import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentsModalComponent } from './payments-modal/payments-modal.component';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent {
  pageSlug: string = 'PAYMENTS_PAGE';
  showPayments = true;
  payments = [
    {
      name: 'Plată cadastru',
      date: new Date(),
      value: 1,
      method: 'Card',
    },
    {
      name: 'Plată cadastru',
      date: new Date(),
      value: 2,
      method: 'Card',
    },
    {
      name: 'Plată cadastru',
      date: new Date(),
      value: 3,
      method: 'Card',
    },
  ];
  savedCards = [
    {
      type: 'VISA',
      expirationDate: new Date(2027, 5, 19),
      number: 4242424242424242,
      lastDigits: 4242,
      ownerName: 'Carabat Ionuț Cosmin',
      savedDate: new Date(),
    },
    {
      type: 'MASTERCARD',
      expirationDate: new Date(2025, 4, 12),
      number: 4242424242424242,
      lastDigits: 4242,
      ownerName: 'Carabat Ionuț Cosmin',
      savedDate: new Date(),
    },
  ];

  constructor(private dialog: MatDialog) { }

  togglePayments($event: Event) {
    $event.preventDefault();
    this.showPayments = true;
  }
  toggleCards($event: Event) {
    $event.preventDefault();
    this.showPayments = false;
  }

  openPaymentsModal() {
    const suportDialogRef = this.dialog.open(PaymentsModalComponent, {
      maxHeight: '95vh',
      maxWidth: '90vw',
      panelClass: 'custom-modalbox',
    });
    Object.assign(suportDialogRef.componentInstance, { suportDialogRef });
  }
}
